import Header from "../../../components/Header";

function BelastingtestPage() {
  return (
    <div>
      <Header currentPage="belastingtest" />
      <div className="container mx-auto px-4 lg:w-2/4 pt-12">
        <div className="mb-4">
          Om nog beter inzicht te geven in hoe je belasting ervoor staat, is er
          een vragenlijst ontwikkeld om na te gaan of je je belast voelt door de
          zorg die je voor je naaste hebt. Hieronder staan 9 vragen over de zorg
          van jouw naaste. Het is de bedoeling dat je bij elk van deze
          uitspraken aangeeft in hoeverre deze in de <u>afgelopen paar weken</u>{" "}
          van toepassing op je zijn. De antwoordmogelijkheden waaruit je kan
          kiezen zijn:
        </div>
        <div className="mb-4">
          <div className="pl-6">
            <div className="pb-2 font-bold" style={{ color: "#203354" }}>
              <span className="px-12">&bull;</span>Nee!
            </div>
            <div className="pb-2 font-bold" style={{ color: "#203354" }}>
              <span className="px-12">&bull;</span>Nee
            </div>
            <div className="pb-2 font-bold" style={{ color: "#203354" }}>
              <span className="px-12">&bull;</span>Min of meer
            </div>
            <div className="pb-2 font-bold" style={{ color: "#203354" }}>
              <span className="px-12">&bull;</span>Ja
            </div>
            <div className="pb-2 font-bold" style={{ color: "#203354" }}>
              <span className="px-12">&bull;</span>Ja!
            </div>
          </div>
        </div>
        <div className="mb-4">
          Pak een stuk papier en een pen en schrijf voor elk van de volgende
          uitspraken op in hoeverre het voor jou van toepassing is (kies uit de
          antwoordmogelijkheden hierboven).
          <div className="flex pt-6">
            <span className="pr-8">1.</span>
            <div className="pl-2">
              Door de situatie van mijn naaste kom ik te weinig aan mijn leven
              toe
            </div>
          </div>
          <div className="flex pt-6">
            <span className="pr-8">2.</span>
            <div className="pl-2">
            Het combineren van de verantwoordelijkheid voor mijn naaste en de
            verantwoordelijkheid voor mijn werk en/of gezin valt niet mee
            </div>
          </div>
          <div className="flex pt-6">
            <span className="pr-8">3.</span>
            <div className="pl-2">
            Door mijn betrokkenheid bij mijn naaste doe ik anderen tekort
            </div>
          </div>
          <div className="flex pt-6">
            <span className="pr-8">4.</span>
            <div className="pl-2">
            Ik moet altijd maar klaarstaan voor mijn naaste
            </div>
          </div>
          <div className="flex pt-6">
            <span className="pr-8">5.</span>
            <div className="pl-2">
            Mijn zelfstandigheid komt in de knel
            </div>
          </div>
          <div className="flex pt-6">
            <span className="pr-8">6.</span>
            <div className="pl-2">
            De situatie van mijn naaste eist voortdurend mijn aandacht
            </div>
          </div>
          <div className="flex pt-6">
            <span className="pr-8">7.</span>
            <div className="pl-2">
            Door mijn betrokkenheid bij mijn naaste krijg ik conflicten thuis
            en/of op mijn werk
            </div>
          </div>
          <div className="flex pt-6">
            <span className="pr-8">8.</span>
            <div className="pl-2">
            De situatie van mijn naaste laat mij nooit los
            </div>
          </div>
          <div className="flex pt-6">
            <span className="pr-8">9.</span>
            <div className="pl-2">
            Ik voel me over het geheel genomen erg onder druk staan door de
            situatie van mijn naaste
            </div>
          </div>
        </div>
        <div className="mb-4 pt-6">
          Kijk nu hoe vaak je de antwoorden ‘Ja!’, ‘Ja’ en ‘Min of meer’ gegeven
          hebt. 
          <div className="flex pl-4 pt-8">
            <div className="pr-2 ">&#x2022;</div>
            <div>
              Is dit 7 tot 9 keer? Dat betekent dat je op het moment erg veel
              druk ervaart bij de zorg van je naaste. Het is dan belangrijk dat
              je ondersteuning opzoekt en actie onderneemt. Ga bijvoorbeeld
              langs bij de huisarts of neem contact op met een casemanager en
              kijk wat hij of zij voor je kan betekenen.
            </div>
          </div>
          <div className="flex pl-4 pt-8">
            <div className="pr-2">&#x2022;</div>
            <div>
              Is dit 4 tot 6 keer? Dan ervaar je op het moment een matige druk
              bij de zorg van je naaste. Kijk wat je in je situatie kan
              veranderen. Pak bijvoorbeeld je energiegevers en vreters aan, of
              doe een beroep op je omgeving.
            </div>
          </div>
          <div className="flex pl-4 pt-8">
            <div className="pr-2">&#x2022;</div>
            <div>
              Is dit 0 tot 3 keer? Dan ervaart je op het moment weinig tot geen
              druk. Voorkomen is beter dan genezen, dus hou jezelf in de gaten
              en blijf goed voor jezelf zorgen.
            </div>
          </div>
        </div>
        <div className="mb-4 pt-6">
          Wanneer je een langere tijd zorgt voor een naaste met dementie, is het
          verstandig om deze vragenlijst eens per maand in te vullen. Zo kan je
          jouw ervaren druk op de langere termijn inzien en krijg je sneller
          door als overbelasting op de loer ligt.
        </div>
        <div className="mb-4">
          Let op: De uitslag van deze test is bedoeld als momentopname en geeft
          niet meer dan een inschatting van hoe je op dit moment ervoor staat. 
        </div>
      </div>
    </div>
  );
}

export default BelastingtestPage;
