import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import texts from "../../texts.json";
import hulpbronnenplaatje from "../../images/Plaatjes_Informatie_over_dementie/hulpbronnen.png";
import informatiedementieplaatje from "../../images/Plaatjes_Informatie_over_dementie/informatie over dementie.png";
import literatuurfilmsplaatje from "../../images/Plaatjes_Informatie_over_dementie/literatuur en films.png";

function InformatieDementiePage() {
  return (
    <div className="App">
      <Header currentPage="informatie-dementie" />
      <div className="w-full container mx-auto pt-12">
        <div className="flex flex-col items-center p-6">
          <div className="text-center max-w-prose pb-4">
            De diagnose dementie roept veel vragen op. Dit kunnen vragen zijn
            over dementie zelf en het verloop ervan, maar ook over
            gedragsveranderingen bij jouw naaste, de veranderende relatie en
            over beschikbare hulpbronnen. Er is veel verschillende informatie en
            hulp beschikbaar, maar soms kan het lastig zijn om jezelf door deze
            overvloed aan informatie te navigeren. Daarom hebben we een
            overzicht van verschillende websites en hulpbronnen gemaakt, zodat
            je snel en gemakkelijk de informatie of hulp kan vinden waar je naar
            op zoek bent.
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 1"
              className="mb-4 w-full h-auto"
              src={informatiedementieplaatje}
            />
            <h2 className="text-2xl font-bold mb-2">
            Informatiebronnen
            </h2>
            <p className="mb-4">Een overzicht van websites waar je informatie kan vinden over dementie.</p>
            <Link
              className="text-blue-600 hover:underline"
              to="/informatie-over-dementie"
            >
              Ga naar het overzicht
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 2"
              className="mb-4 w-full h-auto"
              src={hulpbronnenplaatje}
            />
            <h2 className="text-2xl font-bold mb-2">Hulpbronnen</h2>
            <p className="mb-4">Benieuwd wat voor hulp er beschikbaar is?</p>
            <Link className="text-blue-600 hover:underline" to="/hulpbronnen">
            Kom erachter
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 3"
              className="mb-4 w-full h-auto"
              src={literatuurfilmsplaatje}
            />
            <h2 className="text-2xl font-bold mb-2">Boeken en films</h2>
            <p className="mb-4">Hier vind je aanraders voor boeken en films over dementie.</p>
            <Link
              className="text-blue-600 hover:underline"
              to="/literatuur-films"
            >
              Bekijk de lijst
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InformatieDementiePage;
