import React, { useState } from 'react';

const Button = ({ buttonText }: { buttonText: string }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button 
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="text-white font-bold py-2 px-4 rounded" 
      style={{ 
        backgroundColor: isHovered ? "#10439F" : "#203354", 
        borderColor: "#yourBorderColor", 
        borderWidth: "2px" 
      }}
    >
      {buttonText}
    </button>
  );
}

export default Button;