import Header from "../../../components/Header";
import energiebalans from "../../../images/energie-balans.png";
import energiegeversvreters from "../../../images/energie-gevers-vreters.png";

function EnergieGeversVreters() {
  return (
    <div>
      <Header currentPage="energie-gevers-en-vreters" />
      <div className="container mx-auto px-4 lg:w-2/4">
        <div className="mb-4 pt-12">
          In het leven hebben we activiteiten en mensen die ons energie geven,
          of die ons energie kosten. Dit noemen wij energiegevers en
          energievreters. Dit kunnen bepaalde activiteiten zijn, maar ook mensen
          om ons heen. Wanneer we veel tijd en energie kwijt zijn aan het
          verlenen van zorg, is de kans groot dat je meer energievreters dan
          energiegevers in je leven hebt.
        </div>
        <div className="mb-4">
          Beeld je een weegschaal in, waarbij er in het linker schaaltje de
          energievreters liggen, en in het rechter schaaltje de energiegevers.
          <div className="mb-4 pt-12">
            <img alt="Voeding" className="mb-4" src={energiebalans} />
          </div>
        </div>
        <div className="mb-4 pt-12">
          Op het moment dat de energievreters zwaarder of meer worden en er
          steeds minder tijd is voor energiegevers, ontstaat er een disbalans.
          Wanneer er een disbalans is, kan het zijn dat je meer uitgeput voelt
          en meer stress of meer spanning ervaart. Je haalt namelijk niet
          voldoende energie uit activiteiten die je goed laten voelen. Als dit
          lang genoeg aanhoudt raak je op den duur helemaal op en lukt het je
          niet meer om te zorgen voor zowel jouw naaste als voor jezelf.
        </div>
        <div className="mb-4 pb-4">
          Het is hierdoor goed voor jezelf om in kaart te brengen wat je energie
          geeft en wat energie kost. Het zal geen oplossing zijn voor al je
          problemen, maar het kan je wel helpen om stapjes te zetten naar een
          betere balans in je leven. Hieronder staat een kleine oefening om dit
          voor jezelf in kaart te brengen.
        </div>
        <hr className=""></hr>
        <div className="">
          <div className="pb-2 pt-8">
            <span className="font-bold" style={{ color: "#203354" }}>
              Pak een schrift of een stuk papier
            </span>
            , en maak een tabel van 2 kolommen. In de linker kolom schrijf je
            jouw energiegevers, en in de rechterkolom jouw energienemers. Denk
            hierbij aan bijvoorbeeld activiteiten, personen, situaties en
            voeding. Probeer zo veel mogelijk te bedenken en neem hier zeker 10
            tot 15 minuten de tijd voor om alles op te schrijven. Hieronder
            staat een voorbeeld
            <div className="mb-4 pt-6">
              <img
                alt="Voeding"
                className="mb-4 w-96 h-96 mx-auto block"
                src={energiegeversvreters}
              />
            </div>
          </div>
          <div className="pb-8">
            <span className="font-bold" style={{ color: "#203354" }}>
              Nu je je lijstje hebt gemaakt
            </span>
            , schrijf je achter elk onderwerp een cijfer tussen de 1 en 10,
            hoeveel energie het je geeft of kost. Dus bij het cijfer ‘1’ kost of
            geeft het je een beetje energie, en bij het cijfer ‘10’ kost of
            geeft het je enorm veel energie.
          </div>
          <div className="pb-8">
            <span className="font-bold" style={{ color: "#203354" }}>
              Neem even de tijd {" "}
            </span>
             om alles wat je opgeschreven hebt in je op te nemen. Is er iets wat
            je opvalt? Staat jouw energieweegschaal in balans, of nemen de
            energievreters de overhand?
          </div>
          <div className="pb-8">
            <span className="font-bold" style={{ color: "#203354" }}>
              Als je merkt dat jouw energieweegschaal niet helemaal in balans is
            </span>
            , bedenk dan welke stappen je zou kunnen zetten om meer tot balans
            te komen. Kijk op je lijstje en kies, wanneer mogelijk, een
            energievreter die veel energie kost, maar toch redelijk makkelijk te
            veranderen is. Stel dat je het onderhoud aan jouw tuin al een tijd
            uitstelt, misschien kun je dit aanpakken door hulp te vragen aan een
            buurman/vriend(in)/familielid. Bedenk ook welke energiegevers je
            meer zou willen hebben in je leven, of misschien bedenk je wel iets
            nieuws!
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnergieGeversVreters;
