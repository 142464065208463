import Header from "../../../components/Header";
import boektijdmakendementie from "../../../images/boek1.jpg";
import verborgenzindementie from "../../../images/boek2.jpg";
import dagelijksdementie from "../../../images/boek3.jpg";
import maudbabs from "../../../images/Film1.jpg";
import weipap from "../../../images/film2.jpg";

function LiteratuurFilmsPage() {
  return (
    <div>
      <Header currentPage="literatuur-films" />
      <div className="container mx-auto px-4 lg:w-2/4">
        <div className="mb-4 pt-12">
          Er zijn heel veel verschillende boeken geschreven over dementie. Dit
          zijn bijvoorbeeld informatieve boeken en ervaringsverhalen, maar ook
          romans en kinderboeken. Het lezen van boeken over dementie kan je
          helpen om meer te weten te komen over dementie en te leren hoe je er
          op een goede manier mee kan omgaan. Madeleine Matzer heeft tijdens het
          proces van haar moeder veel gehad aan een aantal boeken. Deze hebben
          haar ook met de ontwikkeling van de voorstelling. Dit is haar top 3:
        </div>
        <div className="mb-4">
          <div className="p-4 italic">
            <div className="italic pb-2">
              <p className="font-bold">
                {" "}
                Tijd maken voor mensen met dementie – 52 manieren om te blijven
                communiceren
              </p>
              <p>Door Kasper Bormans</p>

              <div className="p-4">
                <img
                  alt="alzheimercafe"
                  className="w-64 h-80"
                  src={boektijdmakendementie}
                />
              </div>
            </div>
            <p>
              {" "}
              Wereldwijd zijn er meer dan 50 miljoen mensen met dementie. Dat
              aantal zal verdubbelen om de 20 jaar. Het thema raakt ons
              allemaal. 70% van de personen met dementie woont nog thuis en
              wordt verzorgd door naasten. De aandoening treft op die manier
              drie keer zoveel betrokkenen als er patiënten zijn.{" "}
            </p>
            <p className="pt-2">
              Hoe kunnen we hen inschakelen als hulpbron? Mensen met dementie
              lijken onbereikbaar te zijn, maar toch is er hoopvol onderzoek. We
              kunnen tijd maken voor de ander en de zandloper omkeren. Dit boek
              bevat 52 nieuwe manieren om betekenisvol te blijven communiceren
              met mensen met dementie.
            </p>
          </div>
          Spreekt dit je aan? Klik {"  "}
          <a
            href="https://www.bol.com/nl/nl/p/tijd-maken-voor-mensen-met-dementie/9200000132505103/?bltgh=k7a8jBlmobVDY3Q7KE5PpA.2_6.9.ProductTitle"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            hier
          </a>
          {"  "} om naar de bol.com pagina van dit boek te gaan.
        </div>
        <div className="mb-4 pt-10">
          <div className="p-4 italic">
            <div className="italic  pb-2">
              <p className="font-bold">De verborgen zin van dementie</p>
              <p>Door Hans Stolp</p>
              <div className="p-4">
                <img
                  alt="alzheimercafe"
                  className="w-64 h-80"
                  src={verborgenzindementie}
                />
              </div>
            </div>
            <p>
              In 'De verborgen zin van dementie' beschrijft Hans Stolp een
              andere manier van omgaan met dementie en dementerenden, namelijk
              vanuit het hart. Op dit moment lijden in Nederland zo'n 260.000
              mensen aan dementie en dit worden er elk jaar meer.
            </p>
            <p className="pt-2">
              Hans Stolp geeft met zijn andere kijk op dementie een mogelijke
              oplossing voor dit snelgroeiende maatschappelijke probleem. Hij
              laat ons een andere kant zien: de verborgen geestelijke groei die
              dementie kan bieden. Van buitenaf gezien lijkt dementie zinloos:
              wat is de betekenis van geheugenverlies of het niet meer herkennen
              van geliefden?
            </p>
            <p className="pt-2">
              Dementerenden brengen, nadat ‘plicht’ en ‘moeten’ zijn losgelaten,
              namelijk nieuwe mogelijkheden in zichzelf aan het licht. Deze
              transformatie richting zachtheid en vrijheid maakt het mogelijk om
              een volgend leven meer bevrijd en ontlast te beginnen. Het is de
              hoogste tijd om de spirituele aspecten die met de ziekte dementie
              verbonden zijn te belichten, zodat we anders tegen de ziekte gaan
              aankijken en vooral anders met dementerenden zullen omgaan.
            </p>
          </div>
          Spreekt dit je aan? Klik {"  "}
          <a
            href="https://www.bol.com/nl/nl/p/denken-met-je-hart-1-de-verborgen-zin-van-dementie/9200000036152368/?bltgh=p17KJrE-oAXPONlAc29EXg.2_12.13.ProductTitle)"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            hier
          </a>
          {"  "} om naar de bol.com pagina van dit boek te gaan.
        </div>
        <div className="mb-4 pt-10">
          <div className="p-4 italic">
            <div className="italic  pb-2">
              <p className="font-bold">Dagelijks leven met Dementie</p>
              <p>Door Anne-Mei The</p>
              <div className="p-4">
                <img
                  alt="alzheimercafe"
                  className="w-64 h-80"
                  src={dagelijksdementie}
                />
              </div>
            </div>
            <p>
              Anne-Mei The volgde jarenlang mensen met dementie en hun naasten
              en sprak met honderden betrokkenen en experts. In dit boek
              vertellen zij hoe ze leven met dementie. Hoe zij hun weg hebben
              moeten zoeken, door diepe dalen zijn gegaan en tot belangrijke
              inzichten zijn gekomen over zichzelf, hun relaties en zingeving.
            </p>
            <p className="pt-2">
              The laat zien hoe het dagelijkse leven met dementie verborgen en
              onderbelicht blijft. Hoe mensen met dementie en hun naasten een
              gevoel van leegte ervaren na de diagnose. Hoe zij zich jarenlang
              thuis moeten redden, alleen of met hun partner. Hoe zij niet meer
              als mensen, maar als wilsonbekwame patiënten worden gezien. Hoe
              zij uitgesloten worden van het gewone leven doordat vrienden en
              kennissen wegblijven.
            </p>
            <p className="pt-2">
              The laat ook de machteloosheid zien van de naasten. Hun jarenlange
              verdriet, uitputting en stress. Evenals de machteloosheid van
              zorgverleners die geen echte oplossing kunnen bieden. Anne-Mei The
              houdt een warm pleidooi voor een sociale benadering van dementie
              en biedt daarmee handvatten om het omgaan met dementie draaglijker
              en betekenisvoller te maken.
            </p>
          </div>
          Spreekt dit je aan? Klik {"  "}
          <a
            href="https://www.bol.com/nl/nl/p/dagelijks-leven-met-dementie/9200000035764747/?bltgh=q7S0lVKGrZftxhc0m8tBPQ.2_6.7.ProductTitle"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            hier
          </a>
          {"  "} om naar de bol.com pagina van dit boek te gaan.
        </div>
        <div className="mb-4">
          Op de website dementie.nl vind je verder een hele lijst met
          verschillende boeken en een korte samenvatting. Klik{"  "}
          <a
            href="https://www.dementie.nl/dementie-en-diagnose/uitleg-over-dementie/boeken-over-dementie"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            hier
          </a>
          {"  "}
          om deze te bekijken.
        </div>
        <div className="mb-4 pt-6">
          Ook zijn er ontzettend veel documentaires, films en series die gaan
          over dementie. Op dementie.nl staat een lijst die zij hebben
          samengesteld. Klik{"  "}
          <a
            href="https://www.dementie.nl/dementie-en-diagnose/uitleg-over-dementie/films-en-documentaires"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            hier
          </a>
          {"  "}
          om deze te bekijken. Hieronder lichten we er twee toe.
        </div>
        <div className="">
          <p className="font-bold">Maud en Babs (televisieserie)</p>
          <img alt="alzheimercafe" className="mb-4" src={maudbabs} />
        </div>
        <div className="mb-4">
          Maud & Babs is een televisieserie die op een licht humoristische
          manier de uitdagingen van mantelzorg belicht. Het verhaal draait om
          een gezin dat onder spanning staat door de groeiende zorgbehoeften van
          moeder Babs, die aan beginnende dementie lijdt. Maud, haar dochter,
          verzorgt haar moeder terwijl ze haar eigen verloskundigenpraktijk runt
          en ook zorgt voor haar twee kinderen die nog studeren. Wanneer Babs
          niet meer thuis kan wonen, worden de familieverhoudingen op scherp
          gezet.
        </div>
        <div className="mb-4">
          Bekijk deze serie{"  "}
          <a
            href="https://www.dementie.nl/dementie-en-diagnose/uitleg-over-dementie/films-en-documentaires"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            hier
          </a>
          {"  "}
          op NPO Start.
        </div>
        <div className="pt-10">
          <p className="font-bold">Wei. Zorgen voor pap</p>
          <img alt="alzheimercafe" className="mb-4" src={weipap} />
        </div>
        <div className="mb-4">
          Filmmaker Ruud Lenssen volgt zijn vader Jac nadat hij de diagnose
          dementie heeft gekregen. Terwijl Jac vasthoudt aan zijn idyllische
          wei, worstelt moeder Ria met haar rol als mantelzorger. Ondertussen
          komt een onvermijdelijk afscheid steeds dichterbij.
        </div>
        <div className="mb-4 pb-10">
          Bekijk deze serie{"  "}
          <a
            href="https://www.2doc.nl/documentaires/2020/09/wei.html"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            hier
          </a>
        </div>
      </div>
    </div>
  );
}

export default LiteratuurFilmsPage;
