import Header from "../../../components/Header";
import energiebalans from "../../images/energie-balans.png";
import energiegeversvreters from "../../images/energie-gevers-vreters.png";
import alzheimerdementiegoed from "../../../images/Informatiebronnen/alzheimer_dementie_goed.png";
import thuisarts from "../../../images/Informatiebronnen/thuisarts_goed.png";
import wegwijzergoed from "../../../images/Informatiebronnen/wegwijzer_goed.png";
import breinspoken from "../../../images/Informatiebronnen/breinspoken_goed.png";

function InformatieOverDementie() {
  return (
    <div>
      <Header currentPage="informatie-over-dementie" />
      <div className="container mx-auto px-4 lg:w-2/4 pt-12">
        <div className="mb-4">
          Als je erachter komt dat iemand in je naaste omgeving dementie heeft,
          heb je misschien behoefte aan meer informatie over dementie, en wat
          deze diagnose voor jullie betekent. Op deze pagina willen we je een
          overzicht geven van websites met goede informatie over dementie.
        </div>
        <h2 className="text-2xl text-center font-bold pt-6 pb-6">
          Waar vind je informatie?
        </h2>
        <div className="mb-4 pt-6">
          <img alt="Altzheimer" className="mb-4 mx-auto block w-auto h-32" src={alzheimerdementiegoed} />
        </div>
        <div className="mb-4">
          <div>
            De websites{"  "}
            <a
              href="https://www.alzheimer-nederland.nl/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              alzheimer-nederland.nl
            </a>{" "}
            en{" "}
            <a
              href="https://www.dementie.nl"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              dementie.nl
            </a>{" "}
            zul je waarschijnlijk wel kennen. Deze websites zijn vaak de eerste
            websites die mensen vinden wanneer zij informatie zoeken of krijgen
            over dementie. Deze websites bevatten heel veel informatie voor
            mensen die op zoek zijn naar wat dementie precies is, hoe je met
            iemand met dementie goed kan omgaan, welke hulpbronnen er zijn,
            welke activiteiten er in jouw buurt zijn, etc. Op de website
            dementie.nl kun je ook je verhaal delen met{" "}
            <a
              href="https://www.dementie.nl/ervaringen-delen/forum"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              lotgenoten
            </a>{" "}
            wat erg waardevol kan zijn.
          </div>
        </div>
        <div className="mb-4">
          Voor mensen met dementie, is de website{"  "}
          <a
            href="https://www.ikleefmetdementie.nl/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            ikleefmetdementie.nl
          </a>{" "}
          door Alzheimer-Nederland ontwikkeld. Dit is een laagdrempelige website
          waarbij mensen met dementie informatie krijgen over verschillende
          onderwerpen, zoals zelfstandig blijven, omgaan met familie en
          vrienden, dingen leren en acceptatie van de ziekte.
        </div>
        <div className="mb-4 pt-12">
          <img alt="Thuisarts" className="mb-4 mx-auto block " src={thuisarts} />
        </div>
        <div className="mb-4">
          De website{" "}
          <a
            href="https://www.thuisarts.nl/dementie"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            thuisarts.nl/dementie
          </a>{" "}
          is een website gemaakt door artsen waarbij je veel informatie vindt
          over dementie. De inhoud hiervan lijkt op die van Alzheimer-Nederland,
          maar de focus van deze website is meer gericht op het medische aspect
          en praktische hulp. Ook staan er op deze website doorverwijzingen naar
          andere websites, bijvoorbeeld websites over hulpmiddelen of hulp bij
          zorg regelen.
        </div>
        <div className="mb-4 pt-12">
          <img alt="Wegwijzer" className="mb-4 mx-auto block w-3/4 h-auto" src={wegwijzergoed} />
        </div>
        <div className="mb-4">
          Omdat er op de bovenstaande websites enorm veel informatie staat, kan
          het soms lastig zijn om jezelf hierdoor te navigeren. Daarom zijn er
          digitale wegwijzers gecreëerd: websites die - van informatie van
          verschillende websites - een helder overzicht hebben gecreëerd zodat
          je op een makkelijke en overzichtelijke manier snel informatie en
          antwoorden kunt vinden op jouw (zorg)vragen. Op deze wegwijzers worden
          thema’s aangekaart zoals de eerste signalen van dementie, de diagnose,
          thuis wonen met dementie, wonen in een zorginstelling, de laatste
          levensfase en nazorg.
        </div>
        <div className="mb-4">
          Deze wegwijzers zijn ontwikkeld op basis van de regio waar zij zich in
          bevinden. De informatie op deze websites is voor iedereen ongeacht
          woonplaats nuttig, maar de aanbevelingen op het gebied van beschikbare
          hulp is toegespitst op de regio waar zij zich in bevinden.{" "}
          <div className="p-4">
            <div className="italic pb-2">
              • Voor West-Brabant, klik{" "}
              <a
                href="https://dementienetwerkwb.nl/wegwijzer"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline"
              >
                hier
              </a>{" "}
            </div>
            <div className="italic pb-2">
              • Voor Zuid-Holland, klik{" "}
              <a
                href="https://wegwijzerdementiezhn.nl/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline"
              >
                hier
              </a>{" "}
            </div>
            <div className="italic pb-2">
              • Voor Noord-Holland, klik{" "}
              <a
                href="https://wegwijzerdementie.nl/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline"
              >
                hier
              </a>{" "}
            </div>
          </div>
        </div>
        <div className="mb-4 pt-12">
          <img alt="Breinspoken" className="mb-4 mx-auto block " src={breinspoken} />
        </div>
        <div className="mb-4">
          Voor kinderen van jonge ouders met dementie, is er de website{"  "}
          <a
            href="https://www.breinspoken.nl/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            breinspoken.nl
          </a>{" "}
          Deze website legt op een kindvriendelijke manier uit wat dementie is
          en wat dat betekent voor hen en hun ouders. Deze website is voor
          kinderen van 4 tot 18 jaar en de inhoud is per leeftijdsgroep
          ontwikkeld. Wanneer je een kind hebt of kent met een ouder met
          dementie, is het belangrijk dat een volwassene (de gezonde ouder,
          leerkracht, hulpverlener, opa en oma etc.) hen bij deze website
          begeleid. Mocht je een kind hebben of kennen met een ouder met
          dementie, lees dan eerst even{"  "}
          <a
            href="https://breinspoken.nl/breinspoken-voor-volwassenen"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            deze
          </a>{" "}
          pagina voordat je hen de website laat zien
        </div>
      </div>
    </div>
  );
}

export default InformatieOverDementie;
