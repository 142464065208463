import React from "react";
import Header from "../../../components/Header";
import CardComponent from "../../../components/Card";
import verhaal1 from "../../../images/Ervaringsverhalen/ervaringsverhaal1_goed.png";
import verhaal2 from "../../../images/Ervaringsverhalen/ervaringsverhaal2_goed.png";

function Ervaringsverhalen() {
  return (
    <div className="App">
      <Header currentPage="ervarings-verhalen" />
      <div className="container mx-auto px-4">
        <div className="pt-20">
          <img
            alt="alzheimercafe"
            className="w-1/2 h-auto mx-auto block"
            src={verhaal1}
          />
          <div className="items-center justify-center w-1/2 mx-auto text-left">
            <div className="text-left font-bold pt-6">Ingrid (59)</div>
            <div className="pt-6">
              Mijn moeder Tineke, die al jaren aan Alzheimer leed, had altijd
              een scherp gevoel voor humor, iets wat zelfs haar dementie niet
              kon wegnemen. Er is één verhaal dat me altijd doet glimlachen als
              ik eraan terugdenk, een klein moment van succes en vreugde in onze
              zorg voor haar.
            </div>
            <div className="pt-6">
              Op een dag waren we samen in de keuken bezig met het bakken van
              koekjes, iets wat we vroeger vaak deden. De routine was vertrouwd,
              maar ik kon merken dat mijn moeder moeite had om zich te
              herinneren wat de volgende stappen waren. Met een beetje
              aanmoediging en aanwijzingen van mijn kant, begonnen we toch met
              het mengen van de ingrediënten.
            </div>
            <div className="pt-6">
              Toen we bij het deel kwamen waar we de koekjes moesten vormen en
              op de bakplaat leggen, begon mijn moeder de deegballetjes op de
              meest creatieve manieren te vormen. Sommige leken op kleine
              dieren, anderen op vreemde abstracte vormen. In eerste instantie
              wilde ik haar corrigeren, zodat de koekjes er 'normaal' uit zouden
              zien, maar toen zag ik de twinkeling in haar ogen en de brede
              grijns op haar gezicht.
            </div>
            <div className="pt-6">
              Ze keek naar me op en zei met een ondeugende blik: "Waarom zo
              serieus? Laten we er een beetje plezier in brengen!" Dat moment
              van helderheid, plezier en haar vermogen om te grappen, ondanks
              alles, was zo kostbaar. We lachten samen, en voor even leek de
              Alzheimer naar de achtergrond te verdwijnen.
            </div>
            <div className="pt-6">
              De koekjes kwamen natuurlijk in allerlei vreemde vormen uit de
              oven, maar ze waren het heerlijkste wat we ooit hadden geproefd.
              Niet zozeer vanwege de smaak, maar omdat ze waren gemaakt met
              zoveel liefde, plezier en een vleugje creativiteit.
            </div>
            <div className="pt-6 pb-12">
              Dit moment leerde me iets belangrijks: succes in de zorg voor
              iemand met dementie gaat niet altijd over het vasthouden aan een
              strak schema of het perfectioneren van elke taak. Soms gaat het
              over het omarmen van de chaos, het vinden van momenten van vreugde
              en het toelaten van humor, zelfs als de situatie allesbehalve
              grappig lijkt. Het herinnerde me eraan dat mijn moeder, ondanks
              haar ziekte, nog steeds dezelfde persoon was diep van binnen, met
              dezelfde speelse geest en een geweldig gevoel voor humor.
            </div>
          </div>
        </div>
        <hr className="w-1/2 justify-center mx-auto"></hr>
        <div className="pt-12">
          <img
            alt="verhaal1"
            className="w-1/2 h-auto mx-auto block"
            src={verhaal2}
          />
          <div className="items-center justify-center w-1/2 mx-auto text-left">
            <div className="text-left font-bold pt-6">Jan (72)</div>
            <div className="pt-6">
              Sinds twee jaar ben ik de verzorger van mijn vrouw Lisa, die
              Alzheimer heeft. In het begin was het een enorme aanpassing; ik
              wist niet hoe ik met de veranderende realiteit moest omgaan. Maar
              door de tijd heen, met veel geduld en hulp, heb ik geleerd beter
              om te gaan met de uitdagingen die Lisa's dementie met zich
              meebrengt.
            </div>
            <div className="pt-6">
              Ik herinner me nog goed hoe ons leven samen veranderde. Lisa was
              altijd de rots in de branding geweest, de persoon die alles
              regelde in ons huishouden. Toen ze steeds meer dingen begon te
              vergeten en verwarder leek over dagelijkse dingen, wist ik dat er
              iets serieus aan de hand was. Ze belde me voortdurend op elk
              moment van de dag, zelfs voor de kleinste dingen. Op een gegeven
              moment had ik het gevoel alsof er iets mis was en ben ik met haar
              naar de huisarts gegaan.
            </div>
            <div className="pt-6">
              Na de diagnose voelden we allebei de spanning. Lisa was boos en
              gefrustreerd over de tests en kon niet accepteren dat er iets mis
              was met haar geheugen. Onze relatie werd op de proef gesteld; Lisa
              deed zich vaak beter voor naar buiten toe en gaf mij de schuld van
              dingen die mis gingen. Het was een moeilijke periode waarin ik
              moest leren dat haar gedrag deel was van haar aandoening, niet van
              wie zij als persoon was.
            </div>
            <div className="pt-6">
              Op aanraden van onze casemanager ben ik een cursus gaan volgen
              speciaal voor mantelzorgers. Die cursus was een keerpunt voor mij.
              Ik leerde er om mijn grenzen aan te geven en te begrijpen dat goed
              voor mezelf zorgen essentieel is om goed voor Lisa te kunnen
              zorgen. Het leerde me ook om niet altijd tegen Lisa in te gaan,
              maar om mee te gaan in haar belevingswereld.
            </div>
            <div className="pt-6">
              Zo vertelde Lisa naarmate haar Alzheimer vorderde, steeds vaker
              over haar jeugd op een boerderij. Hoe ze de kippen en andere
              dieren voerde en hoe ze elke ochtend eieren verzamelde. Ondanks
              dat dit verhaal niet klopt met de werkelijkheid en zij niet op een
              boerderij heeft gewoond, besloot ik om mee te gaan in haar
              beleving. "Dat klinkt als hard werken, al die kippen voeden," zei
              ik, spelend op haar verhaal. Ik zag dat Lisa's ogen oplichtte.
              "Het was hard werken, maar ik hield van die dieren, vooral van
              kipje”. Er verscheen een lieve glimlach op haar gezicht, heel
              anders dan wanneer ik haar ervaringen in dat moment niet zou
              hebben erkend.
            </div>
            <div className="pt-6">
              Het meegaan in Lisa's realiteit is niet altijd makkelijk. Soms wil
              ze dingen die niet meer mogelijk zijn, zoals een nieuw huisdier
              nemen nadat ons oude hondje was overleden. Het is een constant
              balanceren tussen meegaan in haar wensen en realistisch blijven
              over wat kan en niet kan.
            </div>
            <div className="pt-6 pb-10">
              De cursus heeft me veel geleerd, maar ik realiseer me dat er
              aspecten zijn waar ik zelf aan moet werken. Tijd nemen voor
              mezelf, me niet schuldig voelen over de keuzes die ik maak, en
              accepteren dat niet alles binnen mijn controle ligt. Dit zijn
              zaken waar ik zelfstandig aan moet werken, en dat blijft een
              uitdaging, maar ik merk dat het mij en Lisa heel veel brengt.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ervaringsverhalen;
