import "./App.css";
import { Routes } from "react-router-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Homepage from "./pages/Homepage/Homepage";
import "@fortawesome/fontawesome-free/css/all.min.css";
import OpnieuwlerenCommunicerenPage from "./pages/OpnieuwCommunicerenHome/OpnieuwlerenCommunicerenPage";
import InformatieDementiePage from "./pages/InformatieDementieHome/InformatieDementiePage";
import ErvaringenSuccessenPage from "./pages/ErvaringenSuccessenHome/ErvaringenSuccessenPage";
import UitlegZorgVoorZelfPage from "./pages/ZelfzorgHome/UitlegZorgVoorZelfPage/UitlegZorgVoorZelfPage";
import EnergieGeversVreters from "./pages/ZelfzorgHome/EnergieGeversVreters/EnergieGeversVreters";
import InformatieOverDementie from "./pages/InformatieDementieHome/InformatieOverDementie/InformatieOverDementie";
import OpnieuwLerenCommuniceren from "./pages/OpnieuwCommunicerenHome/OpnieuwLerenCommuniceren/OpnieuwLerenCommuniceren";
import ScrollToTop from "./components/ScrollToTop";
import OmgaanVeranderendGedragPage from "./pages/OpnieuwCommunicerenHome/OmgaanVeranderendGedrag/OmgaanVeranderendGedragPage";
import BelastingtestPage from "./pages/ZelfzorgHome/Belastingtest/BelastingtestPage";
import HulpbronnenPage from "./pages/InformatieDementieHome/Hulpbronnen/HulpbronnenPage";
import LiteratuurFilmsPage from "./pages/InformatieDementieHome/LiteratuurFilms/LiteratuurFilmsPage";
import OnlinetrainingPage from "./pages/OpnieuwCommunicerenHome/OnlineTraining/OnlineTrainingPage";
import ForumPageCommunicatie from "./pages/ErvaringenSuccessenHome/ForumPages/ForumPageCommunicatie";
import ForumPageZelfzorg from "./pages/ErvaringenSuccessenHome/ForumPages/ForumPageZelfzorg";
import ForumPageSuccessen from "./pages/ErvaringenSuccessenHome/ForumPages/ForumPageSuccessen";
import ZelfzorgHomePage from "./pages/ZelfzorgHome/ZelfzorgHomePage";
import Ervaringsverhalen from "./pages/ErvaringenSuccessenHome/ForumPages/Ervaringsverhalen";

function NotFoundPage() {
  return (
    <div>
      <h1>404 - Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/" element={<Homepage />} />
          <Route
            path="/opnieuw-leren-communiceren"
            element={<OpnieuwlerenCommunicerenPage />}
          />
          <Route
            path="/forum-communicatie"
            element={<ForumPageCommunicatie />}
          />
          <Route path="/forum-zelfzorg" element={<ForumPageZelfzorg />} />
          <Route path="/forum-successen" element={<ForumPageSuccessen />} />
          <Route path="/zelfzorg-mantelzorgers" element={<ZelfzorgHomePage />} />
          <Route
            path="/informatie-dementie"
            element={<InformatieDementiePage />}
          />
          <Route
            path="/ervaringen-successen"
            element={<ErvaringenSuccessenPage />}
          />
          <Route
            path="/uitleg-zorg-voor-zelf"
            element={<UitlegZorgVoorZelfPage />}
          />
          <Route
            path="/energie-gevers-en-vreters"
            element={<EnergieGeversVreters />}
          />
          <Route
            path="/informatie-over-dementie"
            element={<InformatieOverDementie />}
          />
          <Route
            path="/opnieuw-leren-communiceren-page"
            element={<OpnieuwLerenCommuniceren />}
          />
          <Route
            path="/omgaan-veranderend-gedrag"
            element={<OmgaanVeranderendGedragPage />}
          />
          <Route path="/belastingtest" element={<BelastingtestPage />} />
          <Route path="/hulpbronnen" element={<HulpbronnenPage />} />
          <Route path="/literatuur-films" element={<LiteratuurFilmsPage />} />
          <Route path="/online-training" element={<OnlinetrainingPage />} />
          <Route path='/ervarings-verhalen' element={<Ervaringsverhalen />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
