import voeding from "../../../images/voeding.png";
import gebaar from "../../../images/gebaar.png";
import Header from "../../../components/Header";

function UitlegZorgVoorZelfPage() {
  return (
    <div className="App">
      <Header currentPage="uitleg-zorg-voor-zelf" />
      <div className="container mx-auto px-4 lg:w-2/4 pt-12 text-left">
        <div className="mb-4">
          Als je voor iemand met dementie zorgt, kan dat heel stressvol zijn.
          Het is dan ook goed om manieren te vinden om op te laden. Hier zijn 5
          handige tips die je kunnen helpen om je beter te voelen en meer
          energie te hebben.
        </div>
        <h2 className="text-xl font-semibold mb-3 text-center pt-12 pb-6">
          Tip 1: Ontspanning vinden
        </h2>
        <div className="mb-4">
          Wanneer je mantelzorger bent, kan er veel op je af komen, zoals
          regelwerk, emoties en nieuwe situaties waar je mee om moet leren gaan.
          Dit kan ervoor zorgen dat je steeds vaker gespannen en gestrest voelt.
          Daarom is het belangrijk om momenten voor jezelf te nemen om te
          ontspannen en tot rust te komen.
        </div>
        <div className="mb-4">
          Vaak kom je er pas echt achter hoe gespannen je bent als je stilstaat
          bij hoe je lichaam voelt. Het kan dan moeilijk zijn om deze spanning
          los te laten, vooral als je niet gewend bent hoe je dit kan doen. We
          zijn namelijk vaak geneigd om in ons hoofd te zitten en onze
          lichamelijke signalen te negeren. Gelukkig is het mogelijk om jezelf
          aan te leren om naar je lichaam te luisteren. Een manier om dit te
          leren is door middel van ontspanningsoefeningen. In het begin kan dit
          erg lastig zijn omdat je gedachten alle kanten op gaan, maar door vaak
          te oefenen zal dit makkelijker worden. Zo’n oefening zorgt ervoor dat
          je meer bewust wordt van hoe je je nou eigenlijk echt op het moment
          voelt, en helpt je om wat meer te ontspannen.
        </div>
        <div className="mb-4">
          Een makkelijke oefening is de video hieronder; een ademhalingsoefening
          van 3 minuten. Hoe vaker je de tijd neemt om even in te checken met
          jezelf, hoe makkelijker het zal worden om te ontspannen. Een aanrader
          is om deze oefening dagelijks een aantal keer te doen en merk
          vervolgens op wat het met je doet.
          <div className="flex pt-6 pb-6">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/mua06lgX3ME"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="mb-4">
          Ben je benieuwd naar meer oefeningen? VGZ heeft een gratis app genaamd
          '
          <a
            href="https://www.vgz.nl/gezond-leven/mentaal/mindfulness-coach"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            VGZ Mindfulness Coach
          </a>
          '. Op deze app vind je verschillende oefeningen (zowel van korte duur
          als van lange duur) die je helpen om een moment voor jezelf te nemen
          en te ontspannen.
        </div>
        <h4 className="text-xl font-semibold mb-2 text-center pt-12 pb-6">
          Tip 2: Beweging
        </h4>
        <div className="mb-4">
          Beweging is heel belangrijk om je goed te voelen. Het is goed voor je
          hart en je bloedvaten. Daarnaast zorgt beweging voor een betere
          bloeddruk en cholesterol, voor goede doorbloeding en het houdt je
          bloedstolling in balans. Als je genoeg beweegt, kun je de kans op
          hartziekten wel met 20 tot 30% verkleinen.
        </div>
        <div className="mb-4">
          Daarnaast helpt beweging ook met het legen van je hoofd. Het kan
          stress, angst en sombere gevoelens verminderen en zorgt ervoor dat je
          makkelijker in slaap valt en dieper doorslaapt.
        </div>
        <div className="text-xl mb-4 font-bold text-center pt-6">
          Hoeveel moet ik bewegen?
        </div>
        <div>
          Probeer minstens 2,5 uur per week actief te zijn met activiteiten
          zoals wandelen, fietsen, of een andere sport die je leuk vindt. Het is
          ook belangrijk om twee keer per week oefeningen te doen die je spieren
          en botten versterken, zoals gewichtstraining, hardlopen of dansen. Als
          je ouder bent of minder mobiel, kun je overwegen om deel te nemen aan
          een groepsles in de sportschool of thuis spierversterkende oefeningen
          te doen via een YouTube-video. Hieronder vind je een goede video die
          je eens kunt proberen.
          <div className="flex pt-6 pb-6">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/GocO719nCjY?si=BP97At-1wO_DtNeE"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
        <div className="mb-4">
          Ook in het dagelijkse leven zijn er veel activiteiten die je kan
          ondernemen om wat meer beweging te krijgen. Je kunt bijvoorbeeld wat
          vaker de trap in plaats van de lift/roltrap nemen, of je kan lopend of
          met de fiets boodschappen doen in plaats van met de auto. Let er
          vooral op dat je niet te veel zit op een dag.
        </div>
        <div className="mb-4">
          Als je meer wilt lezen over beweging, kijk dan op de website van de
          Hartstichting:
          <div>
            <a
              href="https://www.hartstichting.nl/gezond-leven/bewegen"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://www.hartstichting.nl/gezond-leven/bewegen
            </a>
          </div>
        </div>
        <div className="mb-4">
          Heb je moeite om met bewegen te beginnen? Deze pagina geeft je een
          aantal tips die je kunnen helpen om een goede start te maken:
          <div>
            <a
              href="https://www.ouderenwegwijs.nl/hoe-lukt-het-om-meer-te-bewegen-probeer-deze-tips/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://www.ouderenwegwijs.nl/hoe-lukt-het-om-meer-te-bewegen-probeer-deze-tips/
            </a>
          </div>
        </div>
        <h4 className="text-xl font-semibold mb-2 text-center pt-12 pb-6">
          Tip 3: Slaap
        </h4>
        <div className="mb-4">
          Het staat vast dat we slaap nodig hebben zodat we ons goed en
          uitgerust voelen. Zonder slaap kunnen we namelijk niet overleven.
          Wanneer we een slechte nachtrust hebben, heeft dit negatieve gevolgen
          voor onze gezondheid.
        </div>
        <div className="mb-4">
          In onze slaap verwerken we onze emoties en een goede nachtrust
          verlaagt je stress en vult je energie aan. Wanneer je je uitgerust
          voelt, kan je beter omgaan met lastige situaties zoals de zorg van je
          naaste. Ook zorgt een goede nachtrust ervoor dat we minder snel ziek
          worden. Hoeveel slaap we nodig hebben verschilt per persoon, maar over
          het algemeen ligt dit zo tussen de 7 en 9 uur.
        </div>
        <h4 className="text-xl mb-4 font-bold text-center pt-6">
          Hoe zorg je voor een goede nachtrust?
        </h4>
        <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
          <span className="px-12">&bull;</span>Elke dag op hetzelfde tijdstip
          naar bed
        </div>
        Je lichaam onthoudt wanneer je gaat slapen en opstaat. Wanneer je elke
        dag op hetzelfde tijdstip naar bed gaat, kan je lichaam zich
        voorbereiden op het slaapproces. Er komen allerlei lichamelijke
        processen op gang waardoor je beter en sneller zal slapen. Hetzelfde
        geldt voor opstaan!
        <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
          <span className="px-12">&bull;</span>Ontspanning voor het slapengaan
        </div>
        Zorg ervoor dat je de uren voordat je naar bed gaat, de tijd neemt om je
        lichaam voor te bereiden op slapen. Dim bijvoorbeeld de lichten 2 uur
        van tevoren, lees een boek, neem een bad of maak een avondwandeling.
        Probeer ook te stoppen met het kijken op schermen. Dit zorgt ervoor dat
        je lichaam actief blijft, waardoor het lastiger is om in slaap te vallen
        en de kwaliteit van je slaap minder wordt.
        <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
          <span className="px-12">&bull;</span>Eten en drinken voor het slapen
          gaan
        </div>
        Heb je honger voor het slapen gaan? Het beste kun je een kleine snack
        zoals een appel of een cracker nemen. Grote maaltijden kan je beter
        vermijden; deze zorgen ervoor dat je spijsvertering op gang komt
        waardoor je lichaam harder moet werken. Pas ook op met cafeïne en
        alcohol 's avonds, deze middelen hebben ook effect op de kwaliteit van
        je slaap, waardoor je je 's ochtends minder uitgerust voelt.
        <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
          <span className="px-12">&bull;</span>Zorg dat je slaapkamer een fijne
          plek is
        </div>
        Voor een goede nachtrust is het belangrijk dat je in een prettige kamer
        slaapt. Verschillende factoren zoals afleiding, licht, temperatuur en
        geluid hebben invloed op je slaapkwaliteit. Wanneer je kamer rommelig
        is, kan het ervoor zorgen dat je hersenen geprikkeld blijven. Er wordt
        niet voor niets gezegd: ‘Een opgeruimd huis is een opgeruimd hoofd’. De
        temperatuur en hoe donker je kamer is spelen ook een rol, je wilt je
        kamer zo donker mogelijk hebben en niet te koud of te warm. Als je kamer
        erg gehorig is kun je bijvoorbeeld ervoor kiezen om te slapen met
        oordopjes.
        <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
          <span className="px-12">&bull;</span>Beweeg voldoende overdag
        </div>
        Als je overdag voldoende beweegt zorg je ervoor dat je lichaam en geest
        s’ avonds moe zijn, waardoor je beter zult slapen. Probeer s’ avonds
        niet te intensief te bewegen, want dit kan juist ervoor zorgen dat je
        hersenen geprikkeld blijven wat het lastiger maakt om in slaap te
        vallen. Wil je meer weten over het belang van slaap? Op deze pagina kun
        je meer informatie hierover vinden en kan je inzicht krijgen in de
        kwaliteit van jouw slaap:
        <div className="pt-2">
          <a
            href="https://www.hersenstichting.nl/de-hersenen/gezonde-hersenen/slaap/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            https://www.hersenstichting.nl/de-hersenen/gezonde-hersenen/slaap/
          </a>
        </div>
        <h4 className="text-xl font-semibold mb-2 text-center pt-12 pb-6">
          Tip 4: Gezond eten
        </h4>
        <div className="mb-4">
          Voeding is de basis van onze gezondheid; het zorgt voor energie en
          vitaliteit. Door gezonde producten te eten zorg je ervoor dat je
          lekkerder en fitter in je vel zit, en is de kans kleiner dat je op de
          korte en lange termijn ziek wordt. Ook zorgt een goed voedingspatroon
          dat je beter tegen stress kan en beter slaapt!
        </div>
        <div className="mb-4">
          Misschien heb je al wel eens gehoord van de schijf van vijf. Deze
          schijf is ontwikkeld door het voedingscentrum om inzicht te geven in
          hoe een gezond voedingspatroon eruit zou kunnen zien.
        </div>
        <div className="mb-4">
          <img alt="Voeding" className="mb-4" src={voeding} />
        </div>
        <div className="mb-4">
          <div>
            Op dit plaatje zie je wat richtlijnen die je kan volgen om gezonder
            te eten en drinken. Wil je meer weten over wat gezond eten inhoudt?
            Kijk dan op:
          </div>
          <a
            href="https://www.voedingscentrum.nl/nl/gezond-eten-met-de-schijf-van-vijf.aspx"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            https://www.voedingscentrum.nl/nl/gezond-eten-met-de-schijf-van-vijf.aspx
          </a>{" "}
          of op:{" "}
          <a
            href="https://www.thuisarts.nl/gezond-eten/ik-wil-gezond-eten"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            https://www.thuisarts.nl/gezond-eten/ik-wil-gezond-eten
          </a>
        </div>
        <h4 className="text-xl font-semibold mb-2 text-center pt-12 pb-6">
          Tip 5: Sociale steun
        </h4>
        <div className="mb-4">
          Eenzaamheid is iets wat bij mantelzorgers voor kan komen. Wanneer je
          voor een naaste zorgt, kan het gebeuren dat je minder tijd overhoudt
          voor sociale contacten of activiteiten. Misschien voel je je ook
          minder op je gemak om mensen thuis uit te nodigen, vanwege de toestand
          van je naaste of omdat je huis minder opgeruimd is door de beperkte
          tijd of energie die je hebt.
        </div>
        <div className="mb-4">
          Sociale contacten zijn heel belangrijk om jezelf te ontlasten. Je kan
          bijvoorbeeld je zorgen kwijt bij iemand, of iemand kan je iets voor je
          doen. Ook is het hebben van fijn sociaal contact belangrijk om je op
          te laden!
        </div>
        <div className="mb-4">
          Voor sommige mantelzorgers kan het moeilijk zijn om hulp te vragen. Je
          kan het gevoel hebben alsof je alles alleen moet doen of alsof je
          anderen tot last bent. Helaas weten mensen vaak niet dat je hulp nodig
          hebt als je het ze niet vraagt. Als je om hulp durft te vragen, zal je
          merken dat de meeste mensen je graag willen helpen.
        </div>
        <div className="mb-4">
          Mensen in je omgeving kunnen op verschillende manieren steun bieden:
          <div className="pt-4">
            <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
              <span className="px-12">&bull;</span>Op praktisch gebied:
            </div>
            Mensen kunnen je bijvoorbeeld helpen met boodschappen, het onderhoud
            aan je tuin of kunnen op je naaste letten zodat jij iets anders kan
            ondernemen.
          </div>
          <div className="pt-4">
            <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
              <span className="px-12">&bull;</span>Op emotioneel gebied:
            </div>
            Je kan je zorgen en je verhaal delen bij vrienden, lotgenoten of
            bijvoorbeeld een professional zoals een praktijkondersteuner bij de
            huisarts. Ook een organisatie zoals de luisterlijn (088 0767 000)
            kan je verlichting geven door het bieden van een luisterend oor.
          </div>
          <div className="pt-4">
            <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
              <span className="px-12">&bull;</span>Op informatief gebied:
            </div>
            Je kan informatie over dementie vragen aan bijvoorbeeld een
            lotgenoot, hulpverlener of via een mantelzorgvereniging.
          </div>
        </div>
        <div className="mb-4 pt-12">
          Alzheimer Nederland heeft (gratis) hulpkaartjes gemaakt waardoor hulp
          vragen wat makkelijker wordt. Je kunt ze{" "}
          <a
            href="https://online.alzheimer-nederland.nl/gratis-brievenbus-hulpkaartjes?utm_medium=dementienl"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            hier
          </a>{" "}
          bestellen.
        </div>
        <div className="mb-4">
          <img alt="Gebaar" className="mb-4 pt-10" src={gebaar} />
        </div>
      </div>
    </div>
  );
}

export default UitlegZorgVoorZelfPage;
