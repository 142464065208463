import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import opnieuwlerencommunicerenplaatje from "../../images/Plaatjes_Ervaringen_en_successen_delen/opnieuw leren communiceren en veranderend gedrag.png";
import successendelenplaatje from "../../images/Plaatjes_Ervaringen_en_successen_delen/successen delen.png";
import zelfzorgplaatje from "../../images/Plaatjes_Ervaringen_en_successen_delen/zelfzorg.png";
import ervaringenssuccessenplaatje from "../../images/Ervaringsverhalen/evaringsverhalen.png";

function ErvaringenSuccessenPage() {
  return (
    <div className="App">
      <Header currentPage="ervaringen-successen" />
      <div className="w-full container mx-auto px-6 py-8 text-left">
        <div className="flex flex-col items-center p-6 text-center">
          <div className="max-w-prose pb-4">
            Het kan heel fijn zijn om herkenning te vinden in de verhalen van
            mensen die in een soortgelijke situatie zitten. Of misschien lijkt
            het je prettig om je eigen ervaringen met mensen te delen. Op deze
            pagina kan je in contact komen met lotgenoten. Ook is er een plek om
            je successen te delen. Het zorgen voor iemand met dementie kan
            namelijk ook mooie, leuke of grappige momenten met zich meebrengen.
          </div>
          <div className="max-w-prose pb-4">
            Op deze pagina kan je succesverhalen van anderen bekijken en je
            eigen ervaringen en successen delen.
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 1"
              className="mb-4 w-full h-auto"
              src={opnieuwlerencommunicerenplaatje}
            />
            <h2 className="text-2xl font-bold mb-2">
              Ervaringen over communiceren
            </h2>
            <p className="mb-4">
              Deel hier je ervaringen en/of vragen op het gebied van
              communicatie en gedragsveranderingen.
            </p>
            <Link
              className="text-blue-600 hover:underline"
              to="/forum-communicatie"
            >
              Kom in contact met lotgenoten
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 2"
              className="mb-4 w-full h-auto"
              src={zelfzorgplaatje}
            />
            <h2 className="text-2xl font-bold mb-2">
              Alles over zelfzorg delen
            </h2>
            <p className="mb-4">
              Hier kan je je ervaringen delen of vragen stellen over alles wat
              met zelfzorg te maken heeft.
            </p>
            <Link
              className="text-blue-600 hover:underline"
              to="/forum-zelfzorg"
            >
              Ga naar het forum
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 3"
              className="mb-4 w-full h-auto"
              src={successendelenplaatje}
            />
            <h2 className="text-2xl font-bold mb-2">Successen delen</h2>
            <p className="mb-4">
              Mantelzorg kan ook mooi en betekenisvol zijn. Op deze pagina kun
              je jouw succesverhalen delen.
            </p>
            <Link
              className="text-blue-600 hover:underline"
              to="/forum-successen"
            >
              Deel jouw verhaal
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 3"
              className="mb-4 w-full h-auto"
              src={ervaringenssuccessenplaatje}
            />
            <h2 className="text-2xl font-bold mb-2">Ervaringsverhalen</h2>
            <p className="mb-4">
              Ingrid’s moeder Tineke en Jan’s vrouw Lisa hebben allebei
              Alzheimer. Op deze pagina delen zij hun ervaringen.
            </p>
            <Link className="text-blue-600 hover:underline" to="/ervarings-verhalen">
              Lees hun verhaal
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErvaringenSuccessenPage;
