import React from "react";
import Button from "./Button";
import homepagebanner from "../images/Banners/1. Main page/main page.png";
import opnieuwlerencommunicerenbanner from "../images/Banners/2. Opnieuw leren communiceren/opnieuw_comm_main.png";
import opnieuwlerencommunicerenbannersub from "../images/Banners/2. Opnieuw leren communiceren/opnieuw_comm_sub.png";
import zelfzorgmantelzorgbanner from "../images/Banners/3. Zelfzorg/zelfzorg_main.png";
import informatiedementiebanner from "../images/Banners/4. Informatiebronnen/informatie_hulpbronnen.png";
import ervaringensuccessenbanner from "../images/Banners/5. Delen van ervaringen en successen/ervaringen_successen.png";
import omgaanveranderendgedragbanner from "../images/Banners/2. Opnieuw leren communiceren/veranderend_gedrag.png";
import onlinetrainingbanner from "../images/Banners/2. Opnieuw leren communiceren/veranderend_gedrag_training.png";
import zelfzorgtipsbanner from "../images/Banners/3. Zelfzorg/zelfzorg_tips.png";
import energiegeversvretersbanner from "../images/Banners/3. Zelfzorg/zelfzorg_energie.png";
import belastingtestbanner from "../images/Banners/3. Zelfzorg/zelfzorg_checkup.png";
import informatieoverdementiebanner from "../images/Banners/4. Informatiebronnen/informatie_bronnen.png";
import hulpbronnenbanner from "../images/Banners/4. Informatiebronnen/hulpbronnen.png";
import literatuurfilmsbanner from "../images/Banners/4. Informatiebronnen/boeken_films.png";
import forumcommunicatiebanner from "../images/Banners/5. Delen van ervaringen en successen/delen_communicatie.png";
import zelfzorgbanner from "../images/Banners/5. Delen van ervaringen en successen/delen_zelfzorg.png";
import successenbanner from "../images/Banners/5. Delen van ervaringen en successen/delen_successen.png";
import ervaringsverhalenbanner from "../images/Banners/5. Delen van ervaringen en successen/delen_ervaringsverhalen.png";

import testbanner from "../images/test_banner.png";

interface HeaderProps {
  currentPage: string;
}

const Header: React.FC<HeaderProps> = ({ currentPage }) => {
  let headerImage;
  let imageHeight;

  switch (currentPage) {
    case "homepage":
      headerImage = homepagebanner;
      // imageHeight = "h-36";
      break;
    case "zelfzorg-mantelzorg":
      headerImage = zelfzorgmantelzorgbanner;
      // imageHeight = "h-99";
      break;
    case "informatie-dementie":
      headerImage = informatiedementiebanner;
      // imageHeight = "h-70";
      break;
    case "opnieuw-leren-communiceren":
      headerImage = opnieuwlerencommunicerenbanner;
      // imageHeight = "h-70";
      break;
    case "ervaringen-successen":
      headerImage = ervaringensuccessenbanner;
      break;
    case "opnieuw-leren-communiceren-page":
      headerImage = opnieuwlerencommunicerenbannersub;
      break;
    case "omgaan-veranderend-gedrag":
      headerImage = omgaanveranderendgedragbanner;
      break;
    case "online-training":
      headerImage = onlinetrainingbanner;
      break;
    case "uitleg-zorg-voor-zelf":
      headerImage = zelfzorgtipsbanner;
      break;
    case "energie-gevers-en-vreters":
      headerImage = energiegeversvretersbanner;
      break;
    case "belastingtest":
      headerImage = belastingtestbanner;
      break;
    case "informatie-over-dementie":
      headerImage = informatieoverdementiebanner;
      break;
    case "hulpbronnen":
      headerImage = hulpbronnenbanner;
      break;
    case "literatuur-films":
      headerImage = literatuurfilmsbanner;
      break;
    case "forum-communicatie":
      headerImage = forumcommunicatiebanner;
      break;
    case "forum-zelfzorg":
      headerImage = zelfzorgbanner;
      break;
    case "forum-successen":
      headerImage = successenbanner;
      break;
    case "ervarings-verhalen":
      headerImage = ervaringsverhalenbanner;
      break;
    // Voeg hier meer cases toe voor andere pagina's
    default:
      headerImage = testbanner;
  }

  return (
    <div>
      <header className="w-full bg-white text-black p-2 flex items-center justify-between rounded-bl-lg rounded-br-lg">
        <div className="flex items-center">

        </div>
        <Button buttonText="Inloggen of Registreren" />
      </header>
      <div className="w-full bg-center bg-cover">
        <img
          className={`w-full ${imageHeight}`}
          alt="header"
          src={headerImage}
        ></img>
      </div>
    </div>
  );
};

export default Header;
