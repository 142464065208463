import React from "react";
import Header from "../../../components/Header";
import CardComponent from "../../../components/Card";

function ForumPageCommunicatie() {
  return (
      <div className="App">
        <Header currentPage="forum-communicatie" />
        <div className="container mx-auto px-4">
          <div className="bg-white p-6 rounded-lg shadow-lg my-8">
            <h1 className="text-3xl font-semibold mb-2">
              Communicatie en het veranderend gedrag
            </h1>
            <h2 className="text-gray-700 mb-4">99 gesprekken</h2>
            <p className="text-gray-600 mb-6">
              Op deze pagina kun je je ervaringen delen over de veranderende
              communicatie en/of gedragsveranderingen, en kun je hier vragen
              over stellen.
            </p>
            <div className="flex justify-end space-x-4 mb-6">
              <button className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 transition">
                Start een gesprek
              </button>
            </div>

            <CardComponent
              title="Stel je vraag aan een professional"
              lastResponse="laatste reactie 11 maart 2024 om 09.55"
              comments={45}
              likes={20}
              views={4544}
              user="Gebruiker 123"
            />
            <CardComponent
              title="Hoe beweeg ik mee in een andere belevingswereld?"
              lastResponse="laatste reactie 18 januari 2024 om 10.34"
              comments={79}
              likes={10}
              views={5033}
              user="Gebruiker 444"
            />
            <CardComponent
              title="Is het karakter van jullie naasten ook veranderd?"
              lastResponse="laatste reactie 14 augustus 2023 om 16.21"
              comments={245}
              likes={27}
              views={2300}
              user="Gebruiker 326"
            />
            <CardComponent
              title="Wat moet ik doen? (ongepast gedrag)"
              lastResponse="laatste reactie 24 mei 2023 om 08.14"
              comments={456}
              likes={46}
              views={10000}
              user="Gebruiker 673"
            />
          </div>
        </div>
      </div>
  );
}

export default ForumPageCommunicatie;
