import Header from "../../../components/Header";
import onlinetrainingimage from "../../../images/Online training/online training.png";

function OnlinetrainingPage() {
  return (
    <div className="App">
      <Header currentPage="online-training" />
      <div className="container mx-auto px-4 lg:w-2/4 pt-12 text-left">
        <div className="">
          Om te leren omgaan met veranderend gedrag, heeft Dementie.nl in
          samenwerking met het Trimbos instituut een aantal gratis online
          trainingen en video’s gemaakt over het omgaan met veranderend gedrag.
          In deze trainingen leer je waarom iemand met dementie zulk gedrag
          vertoont, hoe je het herkent en hoe je er beter mee om kan gaan. Je
          krijgt informatie, vragen, oefeningen en video’s over het veranderende
          gedrag.
        </div>
        <div className="pb-4 pt-2">
          Er zijn verschillende trainingen over de volgende thema’s:
          achterdocht, afhankelijkheid, agressie, lusteloos gedrag, onrust in de
          nacht en het verbloemen van dementie. Klik{" "}
          <a
            href="https://www.dementie.nl/training-omgaan-met-veranderend-gedrag"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            hier
          </a>{" "}
          om naar de trainingen te gaan
        </div>
        <div className="justify-center pb-4 pt-8">
          <img
            src={onlinetrainingimage}
            alt="Verleden"
            className="mx-auto block"
          />
        </div>
      </div>
    </div>
  );
}

export default OnlinetrainingPage;
