import Header from "../../../components/Header";
// import alzheimercafe from "../../../images/Alzheimer-café.png";
import alzheimercafe from "../../../images/Hulpbronnen/alzheimer-cafe.png";

function HulpbronnenPage() {
  return (
    <div>
      <Header currentPage="hulpbronnen" />

      <div className="container mx-auto px-4 lg:w-2/4">
        <div className="mb-4 pt-12">
          De zorg voor een naaste met dementie kan erg pittig zijn, en daarom is
          het belangrijk dat er hulpbronnen zijn om je zorg te verlichten. Het
          is voor mantelzorgers niet altijd duidelijk wat voor hulp er
          beschikbaar is. Op deze pagina vind je daarom de meest voorkomende
          hulpbronnen, met uitleg en een doorverwijzing naar de website.
        </div>
        <h3 className="text-1xl font-bold mb-4 pt-10" style={{ color: "#203354" }}>Huisarts</h3>
        <div className="mb-4">
          Wanneer je een vermoeden hebt dat je naaste dementie heeft, zal de
          huisarts de eerste persoon zijn waar je naartoe zal gaan. De huisarts
          is degene die de diagnose zal stellen of je zal doorverwijzen naar een
          specialist. Dit is erg belangrijk, omdat er zonder diagnose vanuit de
          verzekering soms geen hulp wordt vergoed. Daarnaast heeft de huisarts
          veel lijntjes met zorginstanties en kan je met vragen over dementie of
          hulp verder op weg helpen. Ook is het mogelijk om een zorgbehandelplan
          samen te stellen voor de naaste met dementie, en een
          ondersteuningsplan voor de mantelzorger (bron:{" "}
          <a
            href="https://www.dementie.nl/zorg-en-regelzaken/zorg-en-hulp-voor-thuis/de-rol-van-de-huisarts"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            dementie.nl
          </a>
          )
        </div>
        <h3 className="text-1xl font-bold mb-4 pt-10" style={{ color: "#203354" }}>Casemanager</h3>
        <div className="mb-4">
          Eén van de hulpbronnen waar de huisarts je waarschijnlijk over zal
          informeren (en misschien helpen met aanvragen), is de casemanager. Een
          casemanager is een begeleider voor jou en je naaste met dementie, die
          je helpt met alle regels, wetten en zorgmogelijkheden die bij dementie
          komen kijken. Ook kan de casemanager emotionele steun bieden, en
          informatie geven over dementie. Een casemanager wordt vanuit de
          basisverzekering vergoed en is er voor iedereen die dit nodig heeft,
          maar in sommige regio’s is er wel een diagnose nodig. Je kan bij de
          huisarts informeren naar een casemanager, maar je kan online zelf ook
          op zoek gaan. Op de website{"  "}
          <a
            href="https://dementienetwerknederland.nl/regionale-netwerken/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            dementienetwerknederland.nl
          </a>{" "}
          kan je je locatie invoeren en bij jou in de buurt de contactgegevens
          van het dementienetwerk vinden. Zij kunnen jou in contact brengen met
          een casemanager bij jou in de buurt. In de video hieronder van
          Alzheimer-Nederland deelt Marjolein haar ervaringen over haar
          casemanager.
          <div className="flex pt-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/9383yd2a-pg?si=y0tEv-Hp4XVAKW8l"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
          <div className="mb-4 pt-4">
            Wil je meer te weten komen over een casemanager? Kijk dan op{"  "}
            <a
              href="https://www.dementie.nl/zorg-en-regelzaken/zorg-en-hulp-voor-thuis/casemanager-dementie"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              dementie.nl
            </a>
          </div>
        </div>
        <h3 className="text-1xl font-bold mb-4 pt-10" style={{ color: "#203354" }}>Mantelzorgmakelaar</h3>
        <div className="mb-4">
          Een mantelzorgmakelaar is net als de casemanager ook iemand die jou
          ondersteunt met regeltaken, wetten en hulp op het gebied van dementie.
          In tegenstelling tot de casemanager, is een mantelzorgmakelaar er
          specifiek voor de mantelzorger. Een mantelzorgmakelaar helpt de
          mantelzorger te ontlasten door het overnemen van taken die veel
          energie kosten, zodat zij niet overbelast raken en langer voor hun
          naaste kunnen blijven zorgen. Let op: een mantelzorgmakelaar wordt
          niet vergoed vanuit het basispakket, maar is in sommige gemeenten
          gratis. Bij het WMO-loket van jouw gemeente kan je verder vragen over
          de mogelijkheden die er zijn. Wel vergoeden steeds meer verzekeringen
          een mantelzorgmakelaar vanuit de aanvullende verzekering, en ook zijn
          er werkgevers die dit vergoeden. 
        </div>
        <div className="mb-4">
          Op {"  "}
          <a
            href="https://www.bmzm.nl/zoek-mantelzorgmakelaar/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            deze website
          </a>
          {"  "}
          vind je het aanbod van mantelzorgmakelaren in jouw buurt.
        </div>
        <h3 className="text-1xl font-bold mb-4 pt-10" style={{ color: "#203354" }}>Alzheimer-café</h3>
        <div className="mb-4">
          Het Alzheimer-café is een (maandelijkse) bijeenkomst voor iedereen die
          te maken heeft met dementie. Het is een veilige en laagdrempelige plek
          waar je gratis en zonder aanmelding kan aanschuiven. Vaak is er ook
          een professional zoals een arts of een andere deskundige aanwezig.
          Tijdens deze bijeenkomsten wordt een bepaald thema besproken
          (bijvoorbeeld omgaan met dementie) en kan je je eigen ervaringen met
          lotgenoten delen. Ook is er na de bijeenkomst ruimte om vragen te
          stellen of ervaringen uit te wisselen. Op de website van{"  "}
          <a
            href="https://www.alzheimer-nederland.nl/regios/alzheimer-cafe"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            Alzheimer-Nederland
          </a>
          {"  "}
          kan je je locatie aangeven en vind je de bijeenkomsten bij jou in de
          buurt (zie hieronder).
          <div className="pt-6">
            <img alt="alzheimercafe" className="mb-4" src={alzheimercafe} />
          </div>
        </div>
        <div className="mb-4 pt-4">
          Lukt het niet om fysiek aanwezig te zijn, of ben je benieuwd hoe zo’n
          bijeenkomst eraan toe gaat? Soms worden er ook online bijeenkomsten
          gehouden. Op YouTube kun je zoeken naar ‘Alzheimer-Café’, hier vind je
          verschillende bijeenkomsten. Hieronder is een bijeenkomst waarbij een
          mantelzorger het gesprek aangaat.
          <div className="flex pt-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/q6fwqHMGLLk?si=oNjx8N1cL_vh1vtl"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
        <h3 className="text-1xl font-bold mb-4 pt-10" style={{ color: "#203354" }}>Dementielijn</h3>
        <div className="mb-4">
          De dementielijn is er voor je als je advies nodig hebt of gewoon je
          verhaal even aan iemand kwijt wil. Dit telefoonnummer is 7 dagen per
          week gratis bereikbaar van 09.00 uur tot 23.00 uur. Je kunt ze
          bereiken op het telefoonnummer 0800 5088.
        </div>
        <h3 className="text-1xl font-bold mb-4 pt-10" style={{ color: "#203354" }}>Mantelzorgvereniging</h3>
        <div className="mb-4">
          Tot slot zijn er ook mantelzorgverenigingen actief door heel het land.
          Deze verenigingen kunnen je informatie en advies geven met betrekking
          tot mantelzorg, een luisterend oor bieden en je in contact brengen met
          lotgenoten. Op de website{"  "}
          <a
            href="https://www.mantelzorg.nl/organisatie-in-de-buurt/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            mantelzorg.nl
          </a>
          {"  "}
          kan je zoeken naar een mantelzorgvereniging in jouw buurt.
        </div>
      </div>
    </div>
  );
}

export default HulpbronnenPage;
