import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import vijftips from "../../images/Plaatjes_Zelfzorg/zelfzorg_5_tips.png";
import belastingtest from "../../images/Plaatjes_Zelfzorg/zelfzorg_belastingstest.png";
import energiegevers from "../../images/Plaatjes_Zelfzorg/zelfzorg_energiegevers.png";

function ZelfzorgHomePage() {
  return (
    <div className="App">
      <Header currentPage="zelfzorg-mantelzorg" />
      <div className="w-full container mx-auto px-6 py-8 text-left">
        <div className="flex flex-col items-center p-6 text-center">
          <div className="lg:w-2/3 pb-4">
            In de zorg van een ander, is zelfzorg ook een heel belangrijk thema.
            Wanneer je voor iemand met dementie zorgt, kan het zijn dat je jouw
            eigen behoeften en energie gevende activiteiten aan de kant moet
            schuiven. Dit kan ertoe leiden dat je minder energie hebt en minder
            goed in je vel komt te zitten. Als dit lang genoeg aanhoudt, kan je
            uitgeput raken en wordt de alledaagse stress een stuk lastiger om
            mee om te gaan. 
          </div>
          <div className="lg:w-2/3 pb-4">
          Het is daarom belangrijk om een goede balans te
            vinden tussen de zorg van een ander, en de zorg voor jezelf. Als je
            namelijk goed voor jezelf zorgt, kan je ook beter voor een ander
            zorgen. Op deze pagina geven we je wat meer inzicht in wat zelfzorg
            nou eigenlijk is, en krijg je wat tips om een goede balans te vinden
            tussen zorg voor een ander, en zorg voor jezelf.
          </div>

        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 1"
              className="mb-4 w-full h-auto"
              src={vijftips}
            />
            <h2 className="text-2xl font-bold mb-2">5 tips voor zelfzorg</h2>
            <p className="mb-4">Een aantal fijne tips voor zelfzorg</p>
            <Link
              className="text-blue-600 hover:underline"
              to="/uitleg-zorg-voor-zelf"
            >
              Klik hier voor de tips
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 2"
              className="mb-4 w-full h-auto"
              src={energiegevers}
            />
            <div className="text-2xl font-bold mb-2">
              Energiegevers en energievreters
            </div>
            <p className="mb-4">Hoe is jouw energie verdeeld?</p>
            <Link
              className="text-blue-600 hover:underline"
              to="/energie-gevers-en-vreters"
            >
              Kom erachter
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 3"
              className="mb-4 w-full h-auto"
              src={belastingtest}
            />
            <h2 className="text-2xl font-bold mb-2">Mantelzorger check up</h2>
            <p className="mb-4">Hoe belast voel jij je?</p>
            <Link className="text-blue-600 hover:underline" to="/belastingtest">
              Vul de vragenlijst in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZelfzorgHomePage;
