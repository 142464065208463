import React from "react";
import Header from "../../../components/Header";
import CardComponent from "../../../components/Card";

function ForumPageZelfzorg() {
  return (
    <div className="App">
      <Header currentPage="forum-zelfzorg" />
      <div className="container mx-auto px-4">
        <div className="bg-white p-6 rounded-lg shadow-lg my-8">
          <h1 className="text-3xl font-semibold mb-2">Zelfzorg</h1>
          <h2 className="text-gray-700 mb-4">99 gesprekken</h2>
          <p className="text-gray-600 mb-6">
            Op deze pagina kun je jouw ervaringen delen of vragen stellen over
            alles wat met zelfzorg te maken heeft. Heb je een goede zelfzorg tip
            of ben je op zoek naar inspiratie? Deel het hier!
          </p>
          <div className="flex justify-end space-x-4 mb-6">
            <button className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 transition">
              Start een gesprek
            </button>
          </div>

          <CardComponent
            title="Vraag het een ervaringsdeskundige!"
            lastResponse="laatste reactie 11 december 2023 om 23.44"
            comments={66}
            likes={25}
            views={1337}
            user="Gebruiker 740"
          />
          <CardComponent
            title="Hebben jullie tips voor hobby’s die je thuis kan doen?"
            lastResponse="laatste reactie 3 maart 2024 om 13.18"
            comments={123}
            likes={56}
            views={7543}
            user="Gebruiker 999"
          />
          <CardComponent
            title="Hoe ga je om met het aannemen van steun?"
            lastResponse="laatste reactie 14 april 2023 om 20.34"
            comments={333}
            likes={29}
            views={8032}
            user="Gebruiker 163"
          />
          <CardComponent
            title="Ik heb voor het eerst in een lange tijd gesport."
            lastResponse="laatste reactie 2 februari 2024 om 12.11"
            comments={492}
            likes={603}
            views={7331}
            user="Gebruiker 1002"
          />
        </div>
      </div>
    </div>
  );
}

export default ForumPageZelfzorg;
