import React from 'react';

const Card = ({ title, lastResponse, comments, likes, views, user }: { title: string, lastResponse: string, comments: number, likes: number, views: number, user: string }) => {
  return (
    <div className="max-w-2xl mx-auto my-8">
        <div className="bg-blue-100 rounded-full w-10 h-10 flex items-center justify-center">
            <i className="fas fa-user text-blue-600"></i>
        </div>
        <div className="bg-white shadow rounded-lg mt-4 p-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <span className="bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">{user}</span>
                    <span className="text-xs text-gray-500">{lastResponse}</span>
                </div>
                <span className="text-xs text-gray-500">Inloggen vereist</span>
            </div>
            <h3 className="text-lg font-semibold mt-2">{title}</h3>
            <div className="flex items-center justify-between mt-2">
                <div className="flex items-center text-gray-500 text-sm">
                    <i className="far fa-comment mr-1"></i>
                    <span>{comments} reacties</span>
                </div>
                <div className="flex items-center text-gray-500 text-sm">
                    <i className="fas fa-thumbs-up mr-1"></i>
                    <span>{likes} mensen vinden dit behulpzaam</span>
                </div>
                <div className="text-gray-500 text-sm">
                    <i className="far fa-eye mr-1"></i>
                    <span>{views} x gelezen</span>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Card;