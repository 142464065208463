import React from "react";
import logo from "./logo.svg";
import { Link, Routes } from "react-router-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "../../components/Header";
import "./Homepage.css";
import texts from "../../texts.json";
import opnieuwlerencommunicerenplaatje from "../../images/plaatjes_main_page/mainpage_opnieuwcommuniceren.png";
import zelfzorgplaatje from "../../images/plaatjes_main_page/mainpage_zelfzorg.png";
import dementieplaatje from "../../images/plaatjes_main_page/mainpage_informatiedementie.png";
import ervaringensuccessenplaatje from "../../images/plaatjes_main_page/mainpage_ervaringenensuccessendelen.png";

function Homepage() {
  return (
    <div>
      <Header currentPage="homepage" />
      <div className="w-full container mx-auto px-4 py-8">
        <div className="flex justify-center pt-16 pb-8">
          <p className="text-center lg:w-2/3">
            Welkom op het Dementieplein, een plek speciaal ontworpen om naasten
            van mensen met dementie te ondersteunen. Hier vind je handige
            informatie, praktische tips, en kun je ervaringen uitwisselen met
            anderen die in een vergelijkbare situatie zitten. Of je nu op zoek
            bent naar informatie, advies of je eigen verhaal wilt delen, je bent
            hier op de juiste plek.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 pt-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 1"
              className="mb-4 w-full h-auto"
              src={opnieuwlerencommunicerenplaatje}
            />
            <h2 className="font-bold mb-2 text-xl">
              {texts.homepage.lerencommuniceren}
            </h2>
            <p className="mb-4">
              Hoe blijf je in contact met iemand die jou niet meer herkent? En
              hoe ga je om met gedragsveranderingen?
            </p>
            <Link
              className="text-blue-600 hover:underline"
              to="/opnieuw-leren-communiceren"
            >
              Ga naar tips en oefeningen over communicatie
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 2"
              className="mb-4 w-full h-auto"
              src={zelfzorgplaatje}
            />
            <h2 className="font-bold mb-2 text-xl">Zelfzorg</h2>
            <p className="mb-4">
              Als je goed voor jezelf zorgt, kan je ook beter voor een ander
              zorgen. Daarom is het belangrijk om je eigen behoeften niet uit
              het oog te verliezen.
            </p>
            <Link
              className="text-blue-600 hover:underline"
              to="/zelfzorg-mantelzorgers"
            >
              Bekijk tips en oefeningen over zelfzorg
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 3"
              className="mb-4 w-full h-auto"
              src={dementieplaatje}
            />
            <h2 className="font-bold mb-2 text-xl">
              Informatie en hulpbronnen
            </h2>
            <p className="mb-4">
              Op deze pagina vind je een overzicht van handige websites,
              hulpbronnen en boeken en films.
            </p>
            <Link
              className="text-blue-600 hover:underline"
              to="/informatie-dementie"
            >
              Klik hier voor een helder overzicht
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              alt="Plaatje 4"
              className="mb-4 w-full h-auto"
              src={ervaringensuccessenplaatje}
            />
            <h2 className="font-bold mb-2 text-xl">Ervaringen delen</h2>
            <p className="mb-4">
              Het kan erg fijn zijn om herkenning te vinden bij lotgenoten. Op
              deze pagina kan je verhalen van anderen lezen en je eigen
              ervaringen delen.{" "}
            </p>
            <Link
              className="text-blue-600 hover:underline"
              to="/ervaringen-successen"
            >
              Ontdek en deel verhalen
            </Link>
          </div>
        </div>
        <div className="flex justify-center pt-16">
          <div className="text-center lg:w-2/3">
            Deze website is ontworpen als aanvulling op de voorstelling ‘Je kunt
            me gerust een geheim vertellen’ van Madeleine Matzer. Ben je niet
            via de voorstelling op deze website terecht gekomen, en wil je hier
            meer over weten? Kijk dan voor meer informatie op de pagina van de
            voorstelling:{" "}
            <a
            href="https://matzer.org/voorstellingen/je-kunt-me-gerust-een-geheim-vertellen"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            https://matzer.org/voorstellingen/je-kunt-me-gerust-een-geheim-vertellen/
          </a>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
