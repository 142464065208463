import Header from "../../../components/Header";
import hersenschadegoed from "../../../images/Omgaan met gedragsveranderingen/hersenschade_goed.png";
import persoonseigenschappengoed from "../../../images/Omgaan met gedragsveranderingen/persoonseigenschappen_goed.png";
import verledengoed from "../../../images/Omgaan met gedragsveranderingen/verleden_goed.png";
import omgevinggoed from "../../../images/Omgaan met gedragsveranderingen/omgeving_goed.png";
import gezondheidgoed from "../../../images/Omgaan met gedragsveranderingen/gezondheid_goed.png";

function OmgaanVeranderendGedragPage() {
  return (
    <div className="App">
      <Header currentPage="omgaan-veranderend-gedrag" />
      <div className="container mx-auto px-4 lg:w-2/4 text-left pt-12">
        <div className="mb-4">
          Wat kenmerkend is aan elke vorm van dementie, is dat het gedrag van
          iemand verandert. Het lastige is dat je niet kan voorspellen op welke
          manier het gedrag van je naaste verandert. Sommige mensen worden
          sneller boos, wantrouwend, verdrietig of lusteloos. Maar het kan ook
          zijn dat mensen vrolijker en positiever worden dan voorheen. Als je
          wil leren omgaan met gedragsveranderingen, is het belangrijk dat je
          begrijpt hoe veranderend gedrag ontstaat. Vaak is het niet enkel door
          hoe dementie de hersenen beïnvloedt, maar door een combinatie van
          verschillende factoren.
        </div>
        <h1 className="text-2xl text-center font-bold pt-6">
          Waar komen gedragsveranderingen uit voort?
        </h1>
        <div className="">
          <div className="justify-center pb-4 pt-8">
            <img
              src={hersenschadegoed}
              alt="Hersenschade"
              className="mx-auto w-32 h-32 block"
            />
            <p className="font-bold text-center pt-2">Hersenschade</p>
          </div>
          Dementie zorgt voor veranderingen in de hersenen. Onze hersenen
          bevatten ongeveer 86 miljard zenuwcellen, die met elkaar in verbinding
          staan en signalen doorgeven (bron:{" "}
          <a
            href="https://www.hersenstichting.nl/de-hersenen/werking-van-de-hersenen/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            hersenstichting.nl
          </a>
          ). Bij dementie kunnen deze zenuwcellen beschadigd raken, krimpen, of
          de verbindingen ertussen kunnen verloren gaan. Hierdoor kunnen de
          hersenen minder goed werken. Het proces begint vaak in de hippocampus,
          een deel van de hersenen dat belangrijk is voor het geheugen. Na
          verloop van tijd verspreidt de schade zich naar andere delen van de
          hersenen, wat kan leiden tot verschillende problemen zoals moeite met
          spreken, lezen, rekenen, balanceren en gedragsveranderingen.
          <div className="pt-4">
            Hieronder vind je een video waarin Erik Scherder, een bekende
            Nederlandse neuropsycholoog, uitlegt wat er gebeurt in onze hersenen
            bij dementie.
            <div className="flex pt-10 mx-auto justify-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/ah-8CwB15PM?si=yLs0GzOMeSPn72_I"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="">
          <div className="justify-center pb-4 pt-16">
            <img
              src={persoonseigenschappengoed}
              alt="Persoonseigenschappen"
              className="mx-auto w-32 h-32 block"
            />
            <p className="font-bold text-center pt-2">Persoonseigenschappen</p>
          </div>
          Je karakter bepaalt hoe je met verschillende dingen omgaat. Dit zijn
          stabiele eigenschappen die gedurende je leven meestal hetzelfde
          blijven. Bij personen met dementie kan het zijn dat zij hetzelfde
          karakter hebben zoals voorheen. Iemand die steeds zachtaardig en lief
          was, kan ook zo blijven. Dementie kan echter ook zorgen voor
          veranderingen in het karakter. Iemand die bijvoorbeeld altijd rustig
          en vriendelijk was, kan zich bijvoorbeeld agressiever gedragen.
          Andersom is het ook mogelijk: iemand die altijd dominant en
          bekritiserend was, kan ineens heel zachtaardig en zorgzaam worden.
        </div>
        <div className="">
          <div className="justify-center pb-4 pt-16">
            <img
              src={verledengoed}
              alt="Verleden"
              className="mx-auto w-32 h-32 block"
            />
            <p className="font-bold text-center pt-2">Het verleden</p>
          </div>
          Hoe we ons gedragen, komt ook voort uit wat wij in het verleden hebben
          meegemaakt. Het is daarom belangrijk om het levensverhaal van iemand
          te kennen. Heeft iemand bijvoorbeeld iets naars meegemaakt vroeger,
          dan is het mogelijk dat deze ervaring zich in bepaald gedrag uit.
        </div>
        <div className="">
          <div className="justify-center pb-4 pt-16">
            <img
              src={omgevinggoed}
              alt="Verleden"
              className="mx-auto w-32 h-32 block"
            />
            <p className="font-bold text-center pt-2">De omgeving</p>
          </div>
          De omgeving speelt een belangrijke rol in het gedrag van iemand met
          dementie. Dit kan te maken hebben met hoe mensen tegen hen praten of
          zich gedragen, zoals kinderachtig of belerend doen. Mensen met
          dementie hebbend daarom soms het gevoel alsof ze niet gelijkwaardig
          behandeld worden. Ook de plek waar ze wonen is belangrijk. Als hun
          thuis niet goed is aangepast aan hun behoeften, kunnen ze zich beperkt
          voelen en gedragsproblemen ontwikkelen. Er zijn online tips over hoe
          je de leefomgeving kunt aanpassen; voor meer informatie,{"  "}
          <a
            href="https://modernedementiezorg.nl/upl/Notitie%20Woningaanpassingen%20bij%20dementie,%20definitief.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            klik hier
          </a>
          .
        </div>
        <div className="">
          <div className="justify-center pb-4 pt-16">
            <img
              src={gezondheidgoed}
              alt="Verleden"
              className="mx-auto w-32 h-32 block"
            />
            <p className="font-bold text-center pt-2">Lichamelijke factoren</p>
          </div>
          Ook problemen in het lichaam kunnen invloed hebben op hoe iemand met
          dementie zich gedraagt. Vaak kunnen zij niet zelf vertellen dat er
          iets mis is. Zo kan iemand lang last hebben van een oorontsteking,
          maar omdat zij dit niet kan vertellen, uit zich dit in bijvoorbeeld
          somberheid of boosheid. Wanneer die ontsteking verholpen is, verbetert
          hun gedrag meestal ook. Het is belangrijk om regelmatig met je naaste
          naar de dokter te gaan voor een controle. Wil je leren hoe je pijn bij
          iemand met dementie kunt herkennen? Er is een gratis online training
          met video’s die je dit leert.
          {"  "}
          <a
            href="https://www.free-learning.nl/modules/paic15/start.html "
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            Klik hier
          </a>{" "}
          om naar deze training te gaan.
        </div>
        <h1 className="flex justify-center text-2xl font-bold mb-4 pt-20">
          Tips van Madeleine
        </h1>
        <div className="">
          In de beginfase van haar moeders ziekte, had Madeleine moeite met alle
          veranderingen en de soms heftige en snel veranderende stemmingen. In
          haar boekje met tips en hulpmiddelen heeft ze een aantal strategieën
          beschreven die haar geholpen hebben om hiermee om te gaan:
          <div className="">
            <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
              <span className="px-12">&bull;</span>Trek het je niet persoonlijk
              aan:
            </div>
            In het begin is dit zeker niet makkelijk. Maar een eerste stap is
            realiseren dat de persoon met dementie niet bewust is van dit
            gedrag. Het is de aandoening die zo reageert, en het is niet
            specifiek alleen gericht naar jou.
          </div>
          <div className="">
            <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
              <span className="px-12">&bull;</span>Afleiding:
            </div>
            Het zoeken naar afleiding heeft vaak geholpen. Iets aanwijzen wat de
            aandacht trekt, een grappig dansje doen, een foto laten zien, een
            liedje zingen, een ander interessant onderwerp aansnijden. Dit helpt
            niet altijd, maar is vaak wel een manier om de stemming weer te
            keren of kalmeren.
          </div>
          <div className="">
            <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
              <span className="px-12">&bull;</span>In beweging komen:
            </div>
            Soms kan het helpen om zowel letterlijk als figuurlijk in actie te
            komen. Ga bijvoorbeeld samen wandelen! Beweging kan namelijk ook
            helpen om de onrust bij iemand met dementie te verminderen.
          </div>
          <div className="">
            <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
              <span className="px-12">&bull;</span>Besef je dat emotie van korte
              duur kan zijn:
            </div>
            Net zoals een kind dat je achterlaat bij een kinderdagverblijf snel
            van verdrietig naar blij kan gaan, kan de stemming van iemand met
            dementie ook snel veranderen.
          </div>
          <div className="pb-6">
            <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
              <span className="px-12">&bull;</span>Probeer (on)gewone dingen:
            </div>
            Dit bied je de kans om nieuwe dingen te proberen die je normaal
            misschien niet zou doen. Als het niet lukt, wordt het vaak snel
            vergeten. Maar als het succesvol is, heb je samen een waardevol en
            mooi moment gecreëerd waarvan jullie beiden kunnen genieten.
          </div>
        </div>
      </div>
    </div>
  );
}

export default OmgaanVeranderendGedragPage;
