import Header from "../../../components/Header";
// import OpnieuwlerenCommunicerenPage.css from "./OpnieuwlerenCommunicerenPage";

function OpnieuwLerenCommuniceren() {
  return (
    <div className="App">
      <Header currentPage="opnieuw-leren-communiceren-page" />
      <div className="container mx-auto px-4 lg:w-2/4 text-left">
        <div className="pt-12 text-left">
          Waar het vaak misgaat in de communicatie met iemand met dementie, is
          wanneer we ons vast proberen te houden aan de realiteit die wij
          kennen. Dit zijn dingen die wij weten over hoe we praten, de tijd
          beleven, en feiten over mensen en logica. Maar dit kan lastig zijn
          voor mensen met dementie, omdat zij de wereld heel anders beleven. Het
          is daarom belangrijk om een nieuwe manier van communicatie te vinden
          die voor jullie beide werkt. De tips hieronder kunnen je meer inzicht
          geven in hoe je op een andere manier kan communiceren met jouw naaste.
        </div>

        <h1
          style={{ color: "#203354" }}
          className="colors text-2xl font-bold mb-4 pt-12 text-center"
        >
          Wat zijn dingen die je beter kan laten?
        </h1>
        <div className="mb-4">
          Hoewel mensen het vaak goed bedoelen, kan de manier waarop ze
          communiceren met iemand met dementie soms verkeerd uitpakken en een
          tegenovergesteld effect hebben. Hieronder staan een paar tips over wat
          je beter kunt vermijden in de communicatie met iemand met dementie.
        </div>
        <div className="">
          <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
            <span className="px-12">&bull;</span>Je naaste corrigeren
          </div>
          Als je iemand vaak corrigeert, kan dit verdriet of onzekerheid
          veroorzaken omdat diegene dan merkt dat hij of zij iets vergeten is of
          niet meer kan. Het heeft geen zin om te zeggen dat jullie al een
          koekje gegeten hebben. Pak gerust nog een koekje, of stel iets anders
          voor op een vrolijke manier. Dit zorgt een veilig gevoel. Pas je aan
          aan de situatie zoals die komt.
        </div>
        <div className="">
          <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
            <span className="px-12">&bull;</span>Hard/snel praten of fluisteren
          </div>
          Als je hard, snel praat, of fluistert, kan dit het idee geven dat er
          iets niet klopt. Dit kan een gevoel van onrust veroorzaken. Probeer
          daarom in een gesprek rustig en duidelijk te spreken, zodat je goed te
          verstaan bent.
        </div>
        <div className="">
          <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
            <span className="px-12">&bull;</span>Te veel vragen stellen/ testen
          </div>
          Als je probeert te testen wat je naaste nog weet, kan dit verdriet en
          verwarring veroorzaken. Vraag niet te veel naar namen van familieleden
          of gebeurtenissen van de afgelopen week. Je bedoelingen zijn goed,
          maar dit kan stress veroorzaken. Let op wat je naaste nog kan en wat
          niet meer gaat; zo begrijp je beter wat je wel en niet van hem of haar
          kunt verwachten.
        </div>
        <div className="">
          <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
            <span className="px-12">&bull;</span>Doen alsof je vrolijk bent,
            terwijl je dat niet bent
          </div>
          Gedraag je niet vrolijker dan dat je je voelt. Mensen met dementie
          kunnen gevoelens nog goed aanvoelen en merken het als je niet oprecht
          bent.
        </div>
        <div className="">
          <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
            <span className="px-12">&bull;</span>Alles voor de ander doen
          </div>
          Mensen met dementie kunnen nog veel zelf, al gaat het misschien
          langzamer of anders dan je verwacht. Het is daarom belangrijk om
          geduld met ze te hebben en ervoor te zorgen dat ze blijven doen wat ze
          kunnen, zolang het mogelijk is.
        </div>
        <div className="">
          <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
            <span className="px-12">&bull;</span>Op je telefoon zitten tijdens
            je bezoek
          </div>
          Wanneer je op bezoek bent, probeer dan niet te veel op je telefoon te
          kijken. Echt contact maken, ook al is het kort, is beter dan lang
          zonder echte verbinding zijn. Ook al praat je naaste misschien niet
          meer zoals vroeger, hij of zij merkt het snel als jullie energie niet
          in balans is.
        </div>
        <div className="mb-4 pt-4">
          (bron:{" "}
          <a
            href="https://www.dementie.nl/samen-leven-met-dementie/hoe-ondersteun-je-je-naaste/omgaan-met-dementie-wat-je-wel-of-niet-moet-doen"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            dementie.nl
          </a>{" "}
          en Matzer Theaterproducties)
        </div>
        <h1 className="text-2xl font-bold mb-4 pt-12">
          Hoe kom je dan wel in contact met iemand met dementie?
        </h1>
        <div className="mb-4">
          Hieronder vind je een paar tips van Madeleine die je kunnen helpen om
          weer meer in contact te komen met iemand met dementie.
        </div>
        <div className="">
          <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
            <span className="px-12">&bull;</span>Leg contact met aandacht
          </div>
          Zoek eerst contact voordat je begint met praten. Kijk elkaar in de
          ogen aan en pas wanneer je merkt dat je contact en de aandacht hebt,
          begin je met praten. Let ook op je lichaamstaal, dit is bij dementie
          heel belangrijk. Iemand met dementie kan vaak je stemming goed
          aanvoelen. Niet wát je zegt, maar hóe je het zegt kan veel uitmaken.
        </div>
        <div className="">
          <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
            <span className="px-12">&bull;</span>Prikkel de zintuigen
          </div>
          Als praten moeilijk is, probeer dan de zintuigen te prikkelen door
          bijvoorbeeld handen aan te raken of zachtjes te masseren, geuren te
          gebruiken of muziek op te zetten. Muziek kan heel krachtig zijn, zoals
          je hieronder in een video kunt zien.
          <div className="flex pt-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/EEFMjPK1pM8?si=6fV_3aDX6suLeOyV"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
        <div className="">
          <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
            <span className="px-12">&bull;</span>Omarm (opnieuw) je verbeelding
          </div>
          {/* <div className="italic pb-2">3. </div> */}
          Naarmate we ouder worden, verliezen we vaak een deel van onze
          verbeeldingskracht omdat we leren vooral realistisch en logisch te
          denken. Maar als je weer contact maakt met je fantasie en je inbeeldt
          wat allemaal mogelijk is, kan dat een mooi effect hebben in hoe je
          communiceert. Wees ook niet bang om speels te zijn, zoals je met een
          kind zou doen. Dans bijvoorbeeld eens gek of zing een liedje.
        </div>
        <div className="">
          <div
            className="flex pb-2 pt-10 font-bold"
            style={{ color: "#203354" }}
          >
            <span className="px-12">&bull;</span>
            <div>
              Sluit aan bij de belevingswereld van je naaste met dementie
            </div>
          </div>
          Probeer de gewone werkelijkheid even los te laten en stap in de wereld
          zoals je naaste met dementie die beleeft. Durf samen met je naaste die
          wereld te verkennen en te ervaren wat er op dat moment gebeurt. Zeg ja
          tegen hun ervaringen en zie het als een avontuur. In de onderstaande
          video vertelt Angela tegen Alzheimer-Nederland hoe zij dit heeft
          aangepakt.
          <div className="flex pt-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/CqyTkMtEjTQ?si=MT20U6egMp87vzTN"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
        <div className="">
          <div className="pb-2 pt-10 font-bold" style={{ color: "#203354" }}>
            <span className="px-12">&bull;</span>Laat je eigen belangen even
            achterwege
          </div>
          Wanneer je tijd doorbrengt met iemand die dementie heeft, probeer er
          dan helemaal voor die persoon te zijn. Zet je eigen behoeften opzij,
          hoe lastig dit soms ook kan zijn. Door dit te doen, kun je een veel
          waardevollere verbinding krijgen en zo nieuwe mooie herinneringen
          creëren.
        </div>
        <div className="mb-4 pt-4">
          (bron:{" "}
          <a
            href="https://www.dementie.nl"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            dementie.nl
          </a>{" "}
          en Matzer Theaterproducties)
        </div>
        <div className="italic text-center pb-6 pt-6">
          “Als je jezelf en je oordeel thuislaat, als je reageert vanuit het nu
          en als je de verbeelding toelaat, dan kun je prachtige ontmoetingen
          hebben. Zo kan deze ziekte ook een gezamenlijke reis zijn, en een
          hernieuwde kennismaking, intimiteit, contact, humor en mooie
          gesprekken opleveren.”
        </div>
        <div className="text-center pb-8">
          Madeleine Matzer - Schrijver en regisseur ‘Je kunt me gerust een
          geheim vertellen'
        </div>
      </div>
    </div>
  );
}

export default OpnieuwLerenCommuniceren;
