import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import texts from "../../texts.json";
import omgaanveranderendgedragplaatje from "../../images/Plaatjes_Opnieuw_Communiceren/omgaan met veranderend gedrag.png";
import opnieuwlerencommunicerenplaatje from "../../images/Plaatjes_Opnieuw_Communiceren/opnieuw leren communiceren.png";
import onlinetrainingplaatje from "../../images/Online training/online training_image.png";

function OpnieuwlerenCommunicerenPage() {
  return (
    <div className="App">
      <Header currentPage="opnieuw-leren-communiceren" />
      <div className="w-full container mx-auto px-6 py-8 text-center">
        <div className="flex justify-center pt-8 pb-2">
          <p className="lg:w-2/3">
            Dementie kan het contact tussen jou en je naaste flink veranderen.
            Je naaste kan problemen hebben met taal en spraak, iets vertellen
            dat niet klopt, of veranderend gedrag vertonen. Dit is een lastig thema waar veel naasten tegenaan lopen. 
          </p>
        </div>
        <div className="flex justify-center pb-8">
          <p className="lg:w-2/3">
            Hieronder
            vind je een aantal tips en inzichten op het gebied van communicatie,
            om zo fijner contact te krijgen. Ook vind je hier een online
            training waarin je vaardigheden aanleert om beter om te leren gaan
            met gedragsveranderingen.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 ">
          <div className="bg-white p-6 rounded-lg shadow-lg text-left">
            <img
              alt="Plaatje 1"
              className="mb-4 w-full h-auto"
              src={opnieuwlerencommunicerenplaatje}
            />
            <h2 className="text-xl font-bold mb-2">
              Leer opnieuw communiceren
            </h2>
            <p className="mb-4">
              Hoe zorg je ervoor dat je in contact blijft met iemand met
              dementie?
            </p>
            <Link
              className="text-blue-600 hover:underline"
              to="/opnieuw-leren-communiceren-page"
            >
              Klik hier voor informatie en tips
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-left">
            <img
              alt="Plaatje 2"
              className="mb-4 w-full h-auto"
              src={omgaanveranderendgedragplaatje}
            />
            <h2 className="text-xl font-bold mb-2">
              Omgaan met veranderend gedrag
            </h2>
            <p className="mb-4">
              Kom erachter waar gedragsveranderingen vandaan komen en hoe je
              hier mee om kan gaan.
            </p>
            <Link
              className="text-blue-600 hover:underline"
              to="/omgaan-veranderend-gedrag"
            >
              Bekijk de informatie en tips
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-left">
            <img
              alt="Plaatje 2"
              className="mb-4 w-full h-auto"
              src={onlinetrainingplaatje}
            />
            <h2 className="text-xl font-bold mb-2">
              Training omgaan met gedragsveranderingen
            </h2>
            <p className="mb-4">
              Gratis online trainingen in hoe je omgaat met verschillende
              gedragsveranderingen.
            </p>
            <Link
              className="text-blue-600 hover:underline"
              to="/online-training"
            >
              Ga naar de trainingen
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpnieuwlerenCommunicerenPage;
