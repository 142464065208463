import React from "react";
import Header from "../../../components/Header";
import CardComponent from "../../../components/Card";

function ForumPageSuccessen() {
  return (
    <div className="App">
      <Header currentPage="forum-successen" />
      <div className="container mx-auto px-4">
        <div className="bg-white p-6 rounded-lg shadow-lg my-8">
          <h1 className="text-3xl font-semibold mb-2">Succesverhalen delen</h1>
          <h2 className="text-gray-700 mb-4">99 gesprekken</h2>
          <p className="text-gray-600 mb-6">
            Op deze pagina kun je al jouw successen delen met lotgenoten. Ben je
            trots op hoe je een bepaalde situatie hebt aangepakt? En hoe is dat
            geweest voor het contact tussen jou en je naaste? Deel het hier!
          </p>
          <div className="flex justify-end space-x-4 mb-6">
            <button className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 transition">
              Start een gesprek
            </button>
          </div>

          <CardComponent
            title="Voor het eerst echt contact"
            lastResponse="laatste reactie 11 maart 2024 om 12.14"
            comments={231}
            likes={90}
            views={1236}
            user="Gebruiker 8341"
          />
          <CardComponent
            title="Momenten van vreugde en verbinding"
            lastResponse="laatste reactie 18 september 2023 om 14.44"
            comments={79}
            likes={74}
            views={2341}
            user="Gebruiker 512"
          />
          <CardComponent
            title="Ik heb door stilte weer contact gevonden met mijn naaste"
            lastResponse="laatste reactie 14 juni 2023 om 17.10"
            comments={132}
            likes={37}
            views={7454}
            user="Gebruiker 2842"
          />
          <CardComponent
            title="Ik voel me als mantelzorger gezien"
            lastResponse="laatste reactie 24 februari 2024 om 18.56"
            comments={632}
            likes={46}
            views={562}
            user="Gebruiker 122"
          />
        </div>
      </div>
    </div>
  );
}

export default ForumPageSuccessen;
